<template>
  <event-page-layout>
    <div v-if="isLoading" class="w-screen h-screen flex items-center justify-center">
      <Loading />
    </div>
    <div v-else>
      <div :class="event.thumbnail ? 'relative' : ''">
        <BackButton @click="back()" :class="event.thumbnail ? 'absolute' : 'ml-[24px] pt-[36px]'" />
        <EventPageThumbnail v-if="event.thumbnail" :thumbnail-url="event.thumbnail?.url" />
      </div>
      <div id="event-page-blocks-section" class="mb-[48px]">
        <AnnouncementSection v-if="event.announcementText"
        :class="event.thumbnail ? '-mt-6' : 'mt-[12px]'" :body-text="event.announcementText" />
        <div v-for="(block, index) in event.eventPageBlocks" :key="index">
          <EventDetailsSection v-if="block.__component === 'hogc-one-event-page.date-time'"
            :event-name="block.eventName" :event-description="block.eventDescription" :event-time="block.eventTime"
            :event-location="block.eventLocation" :download-asset="block.downloadAsset" :sharing-url="block.sharingURL"
            :date-time="block.dateTime" />
          <DescriptionSection v-if="block.__component === 'hogc-one-event-page.about-event'" :title="block.title"
            :body-text="block.bodyText" :target-demo="block.demographic" />
          <LocationDetails v-if="block.__component === 'hogc-one-event-page.location'"
            :location-name="block.locationName" :location-img-url="block.locationThumbnail?.url"
            :location-body="block.bodyText" :location-url="block.locationURL" />
        </div>
      </div>
      <RegisterButton v-if="event.registrationLink" :is-external="event.registrationLink.isExternal"
        :registration-url="event.registrationLink.URL" />
    </div>
  </event-page-layout>
</template>

<script>
import { mapState } from "vuex";
import EventPageLayout from "@/components/EventPageLayout.vue";
import EventPageThumbnail from "@/components/EventPage/EventPageThumbnail.vue";
import AnnouncementSection from "@/components/EventPage/AnnouncementSection.vue";
import DescriptionSection from "@/components/EventPage/DescriptionSection.vue";
import EventDetailsSection from "@/components/EventPage/EventDetailsSection.vue";
import Loading from "@/components/common/LoadingState.vue";
import BackButton from "@/components/EventPage/BackButton.vue";
import LocationDetails from "@/components/EventPage/LocationDetails.vue";
import RegisterButton from "@/components/EventPage/RegisterButton.vue";

export default {
  components: {
    EventPageLayout,
    EventPageThumbnail,
    AnnouncementSection,
    DescriptionSection,
    EventDetailsSection,
    LocationDetails,
    RegisterButton,
    Loading,
    BackButton,
  },
  computed: {
    ...mapState("eventPage", ["event", "isLoading", "isLightMode"]),
  },
  methods: {
    back() {
      if (window.history.length > 1) {
        this.$router.go(-1);
      } else {
        this.$router.push("/home");
      }
    },
  },
  async ionViewWillEnter() {
    await this.$store.dispatch("eventPage/getEvent", this.$route.params.slug);
    await this.$store.dispatch("updates/getUpdates", "all");
  },
};
</script>
