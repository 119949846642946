import axios from "axios";

const INITIAL_STATE = {
  isLoading: false,
  serviceDates: "",
  services: [],
  selectedServiceIndex: 0,
};

const state = () => INITIAL_STATE;

const mutations = {
  setPage(state, payload) {
    state.serviceDates = payload.serviceDates;
    state.services = payload.services;
  },
  setLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setSelectedServiceIndex(state, index) {
    state.selectedServiceIndex = index;
  }
};

const actions = {
  async fetchPage({ commit, rootState, dispatch }) {
    console.log("Fetching page data")
    commit("setLoading", true);
    try {
      const accessToken = rootState.user.token;
      const { data } = await axios.get(
        `${process.env.VUE_APP_API_URL}/service-highlights`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      commit("setPage", {
        serviceDates: data.serviceDates,
        services: data.services,
      });
    } catch (e) {
      console.error("Error fetching service highlights:", e);
      dispatch("toast/error", "Failed to fetch service highlights", { root: true });
    } finally {
      commit("setLoading", false);
    }
  },
  updateSelectedServiceIndex({ commit, rootState, dispatch}, index) {
    commit("setSelectedServiceIndex", index)
  }
};

const getters = {
  serviceDates(state) {
    return state.serviceDates;
  },
  serviceNames(state) {
    return state.services.map(service => service.name);
  },
  currentService(state) {
    return state.services[state.selectedServiceIndex];
  },
  selectedServiceIndex(state) {
    return state.selectedServiceIndex;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
