<template>
  <birthday-layout
    @on-scroll="handleScroll"
    :enable-scroll-event="true"
    id="board-index"
  >
    <div>
      <BoardContent
        ref="boardContent"
        v-if="this.isYearSelected"
        class="pt-[152px] md:pt-[200px]"
        :class="{ '!pt-[200px]': isIos() }"
        @set-debounce="setDebounce"
      />
      <BoardYearContent
        v-else
        class="pt-[64px]"
        :class="{ '!pt-[88px]': isIos() }"
      />
      <WriteMessageButton />
    </div>
  </birthday-layout>
</template>

<script>
import { mapState } from "vuex";
import BirthdayLayout from "@/components/BirthdayLayout.vue";
import BoardContent from "@/components/Board/Main/BoardContent";
import BoardYearContent from "@/components/Board/Main/BoardYearContent";
import WriteMessageButton from "@/components/Board/Main/WriteMessageButton";
import { isIosNativeOnly } from "@/utils/platform-check";

export default {
  components: {
    BoardContent,
    BoardYearContent,
    WriteMessageButton,
    BirthdayLayout,
  },
  data() {
    return {
      debounce: false,
    };
  },
  computed: {
    ...mapState("board", ["currBoard", "isYearSelected"]),
  },
  async ionViewWillEnter() {
    await this.$store.dispatch("updates/getUpdates", "all");
  },
  methods: {
    isIos() {
      return isIosNativeOnly();
    },
    async handleScroll($event) {
      if (this.debounce) return;
      const scrollElement = await $event.target.getScrollElement();
      if (
        scrollElement.clientHeight + scrollElement.scrollTop >=
        (scrollElement.scrollHeight || scrollElement.clientHeight)
      ) {
        this.debounce = true;
        await this.$refs.boardContent.loadMorePosts();
      }
    },
    setDebounce(value) {
      this.debounce = value;
    },
  },
};
</script>
