<template>
  <div class="flex justify-center items-center">
    <div
      class="z-40 bg-[#EC4E20] rounded-[10px] text-lg w-screen mx-5 min-h-[56px] flex justify-center items-center max-w-[90%]"
    >
      <div class="flex flex-row w-full my-[4px]">
        <div class="flex justify-center items-center min-w-[30px]">
        <img
          src="../../../public/assets/icons/event-page/announcement-icon.svg"
          class="w-[32px] h-[32px] ml-[8px]"
        />
        </div>
        <div class="ml-[12px] text-white text-wrap max-w-[80%] ">
          {{ bodyText }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AnnouncementSection",
  props: {
    bodyText: {
      type: String,
      default: "",
    },
  },
};
</script>
