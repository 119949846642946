import axios from "axios";

const INITIAL_STATE = {
  isLoading: false,
  allPageIcons: [],
  iconTypes: [],
};

const state = () => INITIAL_STATE;

const mutations = {
  setIsLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setAllPageIcons(state, allPageIcons) {
    state.allPageIcons = allPageIcons;
  },
  setIconTypes(state, iconTypes) {
    state.iconTypes = iconTypes;
  },
};

const actions = {
  async getAllPageIcons({ commit, dispatch, rootState }) {
    console.log("getAllPageIcons");
    try {
      commit("setIsLoading", true);
      const accessToken = rootState.user.token;
      const { status, data } = await axios.get(
        process.env.VUE_APP_API_URL + "/allpage/all-page-icons",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (status !== 200) throw new Error("Failed in getting all page icons");
      
      let iconTypes = [];

      // extract icon types
      for (let i = 0; i < data.length; i++) {
        iconTypes.push(data[i].iconType);
      }

      // remove duplicates
      iconTypes = iconTypes.filter((obj1, i, arr) => 
        arr.findIndex(obj2 => (obj2.id === obj1.id)) === i
      )

      // sort by priority
      iconTypes.sort((a, b) => (a.priority > b.priority ? 1 : -1));

      commit("setIconTypes", iconTypes);
      commit("setAllPageIcons", data);
      commit("setIsLoading", false);
    } catch (e) {
      dispatch("toast/error", "Failed to get all page icons.", {
        root: true,
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
