<template>
  <div class="flex flex-col gap-[16px]">
    <h1 class="font-[Inter] text-[24px] leading-[40px] text-white font-black">
      Announcements
    </h1>
    <div v-for="(item, index) in announcements" :key="index">
      <img
        class="w-full rounded-[16px] object-cover"
        :src="item.imageSrc"
        alt="Announcement Image"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    announcements: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
