<template>
  <div class="mx-5 md:mx-[32px] lg:mx-[56px]">
    <div
      class="mt-3 md:mt-0 md:ml-0 border border-1 rounded-xl p-3 mb-3 relative overflow-hidden text-[16px] leading-[24px]"
      :class="{
        'bg-[#EAE8E5] border-[#C0C0C0]': !update.isNew,
        'bg-[#F6F4F0] border-[#3C3A36]': update.isNew,
      }"
    >
      <a :href="update.link" :target="getTarget(update)">
        <div>
          <!-- <CardLabel
            v-if="update.isNew"
            color="yellow"
            class="font-aktiv-grotesk-medium text-[14px] leading-[20px] text-grey-80"
            >New</CardLabel
          > -->
          <div class="font-aktiv-grotest-regular">
            <div class="flex flex-row justify-between items-center mb-3">
              <p
                class="tracking-wide text-[16px] font-bold leading-[24px] pr-3 text-[#1D1D1D] font-aktiv-grotesk w-[227px]"
              >
                <html-to-vue :html="update.title" />
              </p>
              <div
                v-if="update.postedTime != ''"
                class="flex flex-row items-center justify-end font-aktiv-grotesk"
              >
                <p class="tracking-wide text-xs text-[#1D1D1D]">
                  {{ formatPostedTime(update.postedTime) }}
                </p>
                <div
                  v-if="update.isNew"
                  class="rounded-full bg-[#FEC629] ml-3 w-[8px] h-[8px]"
                ></div>
              </div>
            </div>
            <p
              class="tracking-wide text-[16px] leading-[24px] text-[#353535] font-aktiv-grotesk mb-3"
            >
              <html-to-vue :html="update.descriptionShort" />
            </p>
            <div class="flex flex-row justify-between items-center">
              <div></div>
              <div class="flex flex-row items-center gap-2">
                <div class="text-[14px] leading-[20px] text-[#78634F] font-bold">Read More</div>
                <div>
                  <img class="w-[20px] h-[20px]" src="../../../public/assets/icons/updates/read-more.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import momentts from "moment-timezone";
import CardLabel from "../common/CardLabel.vue";

export default {
  name: "UpdatesCard",
  components: { CardLabel },
  props: {
    update: {
      type: Object,
      default: () => {},
    },
    hash: {
      // type: String,
      type: Boolean,
      default: () => {},
    },
  },
  data() {
    return {
      show: false,
      // descriptionLength: 70,
      months: [
        ["Jan", "January"],
        ["Feb", "February"],
        ["Mar", "March"],
        ["Apr", "April"],
        ["May", "May"],
        ["Jun", "June"],
        ["Jul", "July"],
        ["Aug", "August"],
        ["Sep", "September"],
        ["Oct", "October"],
        ["Nov", "November"],
        ["Dec", "December"],
      ],
      test: "test",
    };
  },
  computed: {},

  methods: {
    formatPostedTime(time) {
      momentts.tz.setDefault("Asia/Singapore");
      // return moment(time).calendar()
      const currentDate = momentts();
      // const currentDate = '2022-09-03T16:00:00.000Z'
      const modifiedDateArray = momentts(String(time))
        .format("DD MM hh:mm a")
        .split(" ");
      let finalDateString = null;
      if (
        currentDate.startOf("day").toString() ===
        momentts(time).startOf("day").toString()
      ) {
        finalDateString =
          // "TODAY, " +
          modifiedDateArray[2] + modifiedDateArray[3].toUpperCase();
      } else {
        const monthsIndex = modifiedDateArray[1] - 1;
        modifiedDateArray[1] = this.months[monthsIndex][0].toUpperCase();
        finalDateString =
          modifiedDateArray[0] + " " + modifiedDateArray[1].toUpperCase();
        // +
        // ", " +
        // modifiedDateArray[2] +
        // modifiedDateArray[3].toUpperCase();
      }
      return finalDateString;
    },
    getTarget(item) {
      return item.isExternal === true ? "_blank" : "_self";
    },
  },
};
</script>
<style scoped>
p >>> a {
  color: #00aae4;
  font-weight: 600;
  text-decoration: underline;
}
</style>
