import axios from "axios";

const INITIAL_STATE = {
  isLoading: false,
  middleNavIcons: [],
  featuredCards: [],
  justForYou: [],
};

const state = () => INITIAL_STATE;

const mutations = {
  setIsLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setMiddleNavIcons(state, middleNavIcons) {
    state.middleNavIcons = middleNavIcons;
  },
  setFeaturedCards(state, featuredCards) {
    state.featuredCards = featuredCards;
  },
  setJustForYou(state, justForYou) {
    state.justForYou = justForYou;
  },
};

const actions = {
  async getMiddleNavIcons({ commit, dispatch, rootState }) {
    try {
      commit("setIsLoading", true);
      const accessToken = rootState.user.token;
      const { status, data } = await axios.get(
        process.env.VUE_APP_API_URL + "/homepage/middle-nav-icons",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (status !== 200) throw new Error("Failed in getting middle nav icons");
      commit("setMiddleNavIcons", data);
      commit("setIsLoading", false);
    } catch (e) {
      dispatch("toast/error", "Failed to get middle nav icons.", {
        root: true,
      });
    }
  },
  async getFeaturedCards({ commit, dispatch, rootState }) {
    try {
      commit("setIsLoading", true);
      const accessToken = rootState.user.token;
      const { status, data } = await axios.get(
        process.env.VUE_APP_API_URL + "/homepage/featured",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (status !== 200) throw new Error("Failed in getting featured cards");
      commit("setFeaturedCards", data);
      commit("setIsLoading", false);
    } catch (e) {
      dispatch("toast/error", "Failed to get featured cards.", {
        root: true,
      });
    }
  },
  async getJustForYou({ commit, dispatch, rootState }) {
    try {
      commit("setIsLoading", true);
      const accessToken = rootState.user.token;
      const { status, data } = await axios.get(
        process.env.VUE_APP_API_URL + "/homepage/just-for-you",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (status !== 200) throw new Error("Failed in getting just for you");
      commit("setJustForYou", data);
      commit("setIsLoading", false);
    } catch (e) {
      dispatch("toast/error", "Failed to get just for you.", {
        root: true,
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
