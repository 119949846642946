<template>
  <div class="bottom-0" :class="numberOfComponents > 2 ? 'sticky' : 'absolute'">
    <router-link v-if="!isExternal" :to="registrationUrl" class="w-[100vw] h-[80px] items-center justify-center flex backdrop-blur-sm bg-[#00000020]">
      <div class="bg-[#FEC629] rounded-[20px] my-auto w-[90%] text-center font-bold h-12 py-3 text-xl text-black">
        <div>Register Now</div>
      </div>
    </router-link>
    <a v-else :href="registrationUrl" target="_blank" class="w-[100vw] h-[80px] items-center justify-center flex backdrop-blur-sm bg-[#00000020]">
      <div class="bg-[#FEC629] rounded-[20px] my-auto w-[90%] text-center font-bold h-12 py-3 text-xl text-black">
        <div>Register Now</div>
      </div>
    </a>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "RegisterButton",
  computed: {
    ...mapState("eventPage", ["numberOfComponents"]),
  },
  props: {
    isExternal: {
      type: Boolean,
      default: false,
    },
    registrationUrl: {
      type: String,
      default: "",
    },
  },
};
</script>
