import { createApp } from "vue/dist/vue.esm-bundler";
import App from "./App.vue";
import router from "./router";

import { IonicVue } from "@ionic/vue";
import MasterLayout from "./components/MasterLayout.vue";
import EmptyLayout from "./components/EmptyLayout.vue";

import store from "./store";
import { createLogoutUri, redirectDeepLinkPath } from "./utils/redirect-uri";
import { Browser } from "@capacitor/browser";
import { auth0 } from "./utils/auth0";
import HtmlToVue from "./components/common/HtmlToVue.vue";
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css"; //required if you're not going to override default slots
import VueCookies from 'vue3-cookies'
import VueClickAway from "vue3-click-away";
import {
  isNativeNotWeb,
  isAndroidNativeOnly,
  isIosNativeOnly,
} from "./utils/platform-check";
import OneSignal from "onesignal-cordova-plugin";


/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";

/* TailwindCSS */
import "./styles/main.scss";
import "tailwindcss/base.css";
import "tailwindcss/components.css";
import "tailwindcss/utilities.css";

const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(auth0)
  .use(store)
  .use(VueCookies)

  .component("master-layout", MasterLayout)
  .component("empty-layout", EmptyLayout)
  .component("html-to-vue", HtmlToVue)
  .component("infinite-loading", InfiniteLoading);

router.isReady().then(() => {
  app.use(VueClickAway)
  if (process.env.VUE_APP_NODE_ENV === "local") {
    const token = localStorage.getItem("token");
    if (token) store.commit("user/setToken", token);
    app.mount("#app");
    return;
  }
  auth0
    .getAccessTokenSilently({ cacheMode: "cache-only" })
    .then((token) => {
      store.commit("user/setToken", token);
    })
    .catch((err) => {
      if (err.error === "login_required") {
        auth0.logout({
          logoutParams: {
            returnTo: createLogoutUri(
              process.env.VUE_APP_PACKAGE_ID,
              process.env.VUE_APP_AUTH0_DOMAIN
            ),
          },
          openUrl: async (url) =>
            await Browser.open({
              url,
              windowName: "_self",
            }),
        });
      }
      console.log("in access token error");
      console.error(err);
    })
    .finally(() => {
      app.mount("#app");
      if (isNativeNotWeb() && process.env.VUE_APP_ONESIGNAL_API_KEY) {
        OneSignal.initialize(process.env.VUE_APP_ONESIGNAL_API_KEY);
        OneSignal.Notifications.requestPermission();
        OneSignal.Notifications.addEventListener("click", async (event) => {
          console.log("The notification was clicked!", event);

          const isInternal = redirectDeepLinkPath(
            router,
            event.notification.launchURL
          );
          if (!isInternal && isIosNativeOnly()) {
            console.log("external url: ", event.notification.launchURL);
            await Browser.open({ url: event.notification.launchURL });
          }
        });
      }
    });
});

app.config.errorHandler = async function (err, vm, info) {
  if (process.env.VUE_APP_NODE_ENV !== "production") {
    console.log("in error handler");
    console.error(err);
  }

  // TODO datadog error monitoring
  if (err.message === "Request failed with status code 401") {
    await auth0.logout({
      logoutParams: {
        returnTo: createLogoutUri(
          process.env.VUE_APP_PACKAGE_ID,
          process.env.VUE_APP_AUTH0_DOMAIN
        ),
      },
      openUrl: async (url) =>
        await Browser.open({
          url,
          windowName: "_self",
        }),
    });
  }
};

window.onerror = function (message, source, lineno, colno, error) {
  if (process.env.VUE_APP_NODE_ENV !== "production") {
    console.log("in window error handler");
    console.log(message);
    console.error(error);
  }
  // TODO datadog error monitoring
};

window.addEventListener("unhandledrejection", async (event) => {
  if (process.env.VUE_APP_NODE_ENV !== "production") {
    console.log("in unhandled rejecction");
    console.error(event);
    console.log(event.reason.message);
  }
  if (
    event.reason &&
    event.reason.message &&
    (event.reason.message === "Request failed with status code 401" ||
      event.reason.message === "Login required" ||
      event.reason.message.startsWith("Missing Refresh Token") ||
      event.reason.message.startsWith("Unknown or invalid refresh token"))
  ) {
    await auth0.logout({
      logoutParams: {
        returnTo: createLogoutUri(
          process.env.VUE_APP_PACKAGE_ID,
          process.env.VUE_APP_AUTH0_DOMAIN
        ),
      },
      openUrl: async (url) =>
        await Browser.open({
          url,
          windowName: "_self",
        }),
    });
  }
  // TODO datadog error monitoring
});
