import axios from "axios";
const INITIAL_STATE = {
  isLoading: true,
  isLightMode: false,
  numberOfComponents: 0,
  event: {},
};

const state = () => INITIAL_STATE;

const mutations = {
  setLightMode(state, isLightMode) {
    state.isLightMode = isLightMode;
  },
  setEvent(state, event) {
    state.event = event;
  },
  setIsLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setNumberOfComponents(state, numberOfComponents) {
    state.numberOfComponents = numberOfComponents;
  },
};

const actions = {
  async getEvent({ commit, dispatch, rootState }, slug) {
    try {
      commit("setIsLoading", true);
      const accessToken = rootState.user.token;
        const { status, data } = await axios.get(
            process.env.VUE_APP_API_URL + "/eventpage/" + slug,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
        );
        if (status !== 200) throw new Error("Failed in getting event");
        const event = data[0];
        const numberOfComponents = event.thumbnail ? event.eventPageBlocks.length + 1 : event.eventPageBlocks.length;
        commit("setEvent", event);
        commit("setLightMode", event.isLightMode);
        commit("setNumberOfComponents", numberOfComponents);
        commit("setIsLoading", false);  
        } catch (e) {
        dispatch("toast/error", "Failed to get event.", {
            root: true,
        });
        }
    },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
