<template>
  <div
    class="shadow-md rounded-lg mx-5 px-4 py-3 mt-4"
    :class="isLightMode ? 'bg-white' : 'bg-[#2B2926]'"
  >
    <div class="flex justify-between items-center gap-x-1">
      <div class="font-semibold text-[16px] w-1/2">
        {{ title }}
      </div>
      <div v-if="targetDemo" class="flex flex-row justify-self-end">
        <img
          src="../../../public/assets/icons/event-page/profile-icon.svg"
          :class="{ invert: isLightMode }"
        />
        <div class="text-[10px] ml-[4px]">
          {{ targetDemo }}
        </div>
      </div>
    </div>
    <div class="text-sm mt-[12px]" v-html="bodyText">
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "DescriptionSection",
  props: {
    title: {
      type: String,
      default: "",
    },
    bodyText: {
      type: String,
      default: "",
    },
    targetDemo: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState("eventPage", ["isLightMode"]),
  },
};
</script>