<template>
  <master-layout>
    <div
      class="flex flex-row justify-between w-full px-5 pt-4 pb-2 bg-[#F6F5F1]"
      :class="{ '!pt-[60px]': isIos() }"
    >
      <div>
        <router-link to="/home"
          ><img
            class="w-[32px] h-[32px]"
            src="../../../public/assets/icons/back.svg"
        /></router-link>
      </div>
      <div @click="myQr()">
        <img
          class="w-[32px] h-[32px]"
          src="../../../public/assets/icons/mepage/qr-code.svg"
        />
      </div>
    </div>
    <div
      class="flex flex-col gap-2 w-full px-8 mx-auto items-center bg-[#F6F5F1]"
    >
      <img
        class="rounded-full w-[64px] h-[64px] bg-[#FFE34E]"
        src="../../../public/assets/icons/mepage/photo-icon.png"
      />
      <div class="text-[24px] leading-[32px] font-bold">
        {{ fullname }}
      </div>
      <div
        class="rounded-[100px] border-[1px] border-[#A2A2A2] h-[28px] text-[12px] leading-[20px] font-weight-600 py-1 px-2"
      >
        Complete Profile
      </div>
    </div>
    <div
      class="flex flex-col w-full h-full pt-8 px-8 mx-auto items-center bg-[#F6F5F1]"
    >
      <div class="flex flex-row justify-between w-full py-3">
        <div class="flex flex-row gap-4">
          <img
            class="w-[24px] h-[24px]"
            src="../../../public/assets/icons/mepage/personal-info.svg"
          />
          <div class="text-[16px] leading-[24px] font-normal">
            Personal Information
          </div>
        </div>
        <img
          class="w-[24px] h-[24px]"
          src="../../../public/assets/icons/mepage/chevron-right.svg"
        />
      </div>
      <div class="flex flex-row justify-between w-full py-3">
        <div class="flex flex-row gap-4">
          <img
            class="w-[24px] h-[24px]"
            src="../../../public/assets/icons/mepage/account-settings.svg"
          />
          <div class="text-[16px] leading-[24px] font-normal">
            Account Settings
          </div>
        </div>
        <img
          class="w-[24px] h-[24px]"
          src="../../../public/assets/icons/mepage/chevron-right.svg"
        />
      </div>
      <div class="flex flex-row justify-between w-full py-3">
        <div class="flex flex-row gap-4">
          <img
            class="w-[24px] h-[24px]"
            src="../../../public/assets/icons/mepage/notification-settings.svg"
          />
          <div class="text-[16px] leading-[24px] font-normal">
            Notification Settings
          </div>
        </div>
        <img
          class="w-[24px] h-[24px]"
          src="../../../public/assets/icons/mepage/chevron-right.svg"
        />
      </div>
      <div class="flex flex-row justify-between w-full py-3">
        <div class="flex flex-row gap-4">
          <img
            class="w-[24px] h-[24px]"
            src="../../../public/assets/icons/mepage/mygiving.svg"
          />
          <div class="text-[16px] leading-[24px] font-normal">MyGiving</div>
        </div>
        <div></div>
      </div>
      <div class="flex flex-row justify-between w-full py-3">
        <div class="flex flex-row gap-4">
          <img
            class="w-[24px] h-[24px]"
            src="../../../public/assets/icons/mepage/help.svg"
          />
          <div class="text-[16px] leading-[24px] font-normal">Help</div>
        </div>
        <div></div>
      </div>
      <div
        class="flex flex-row justify-between w-full py-3 cursor-pointer"
        @click="logoutUser"
      >
        <div class="flex flex-row gap-4">
          <img
            class="w-[24px] h-[24px]"
            src="../../../public/assets/icons/mepage/sign-out.svg"
          />
          <div class="text-[#EC4E20] text-[16px] leading-[24px] font-normal">
            Sign Out
          </div>
        </div>
        <div></div>
      </div>
    </div>
  </master-layout>
</template>
<script>
import { mapGetters } from "vuex";
import { isIosNativeOnly } from "@/utils/platform-check";
import Logout from "@/mixins/Logout.js";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { isPlatform } from "@ionic/vue";

export default {
  mixins: [Logout],
  computed: {
    ...mapGetters({
      fullname: "profile/fullname",
    }),
  },
  methods: {
    isIos() {
      return isIosNativeOnly();
    },
    myQr() {
      const isNative = isPlatform("android") || isPlatform("ios");
      const isNativeBrowser = isPlatform("mobileweb");
      if (isNative && !isNativeBrowser) {
        FirebaseAnalytics.logEvent({
          name: "click_button",
          params: {
            button_name: "QR Code - Me",
            page: "MePage",
          },
        });
      }
      this.$router.push("/my-qr");
    },
  },
};
</script>
