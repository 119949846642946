<template>
  <master-layout id="calendar-top" :enable-scroll-event="true" @on-scroll="onScroll">
    <StickyHeader :peek-height="10" :header-height="48" scrollElementId="calendar-top">
      <template #left>
        <router-link to="/home"><img class="w-[32px] h-[32px]"
            src="../../../public/assets/icons/back.svg" /></router-link>
      </template>
      <template #right>
        <div class="px-[8px] py-[4px] rounded-[16px] bg-[#191817]" v-if="isLeaderEvents">
          <p class="text-[12px] text-white font-semibold">Leaders</p>
        </div>
      </template>
      <template #bottom>
        <div class="flex items-center justify-between px-[24px] pt-[4px] pb-[8px]">
          <div class="bg-[#E8DCC7] pl-[12px] pr-[10px] py-[8px] rounded-[8px] flex cursor-pointer"
            @click="isSelectorOpen = true">
            <p class="text-[#3C3A36] text-[16px] leading-[20px] font-black uppercase mr-[8px]">{{ topMonth }}</p>
            <img class="w-[16px] h-[16px] mt-[1px]" src="../../../public/assets/icons/chevron-down.svg" alt="" />
          </div>
          <div class="flex">
            <div class="border-[1px] border-solid border-[#3C3A36] rounded-[10px] w-[36px] h-[36px] p-[8px] mr-[8px]">
              <img class="w-[20px] h-[20px]" src="../../../public/assets/icons/calendar/calendar.svg"
                alt="filter-icon" />
            </div>
            <div class="border-[1px] border-solid border-[#3C3A36] rounded-[10px] w-[36px] h-[36px] p-[8px]"
              @click="isFilterOpen = true">
              <img class="w-[20px] h-[20px]" src="../../../public/assets/icons/calendar/filter-icon.svg"
                alt="filter-icon" />
              <p v-if="selectedEvents.length > 0"
                class="relative top-[-32px] left-[18px] rounded-full bg-[#EC4E20] w-min min-w-[18px] h-[18px] text-[10px] text-center leading-[14px] py-[2px] px-[4px] font-bold text-[#FFFFFF]">
                {{ selectedEvents.length }}
              </p>
            </div>
          </div>
        </div>
      </template>
    </StickyHeader>
    <div class="flex-1 min-h-screen bg-[#F6F4F0]">
      <div class="pt-[16px]">
        <CalendarList class="pt-[50px]" :months="months" :monthEvents="monthEvents" ref="calendarList" />
      </div>
    </div>
    <Modal v-model="isFilterOpen">
      <template #header>
        Filter View
      </template>
      <CheckboxGroup v-model="selectedEvents" :options="tagOptions" />
    </Modal>
    <Modal v-model="isSelectorOpen">
      <template #header>
        Select Month
      </template>
      <YearRadioGroup v-model="selectedMonth" :options="monthOptions" />
    </Modal>
  </master-layout>
</template>

<script>
import CalendarList from "@/components/Calendar/Events/CalendarList.vue";
import StickyHeader from "@/components/common/StickyHeader.vue";
import CheckboxGroup from "@/components/common/CheckboxGroup.vue";
import YearRadioGroup from "@/components/common/YearRadioGroup.vue";
import Modal from "@/components/common/Modal.vue";
import eventData from "../../../public/assets/calendar.json";
import dayjs from "dayjs";


export default {
  components: {
    CalendarList,
    StickyHeader,
    CheckboxGroup,
    YearRadioGroup,
    Modal
  },
  data() {
    return {
      topMonth: '',
      months: [],
      monthEvents: [],
      tags: [],
      selectedEvents: [],
      selectedMonth: '',
      tagOptions: [],
      monthOptions: [],
      scrollValue: 0,
      currentYear: dayjs().year(),
      currentMonth: dayjs().month() + 1,
      isFetching: false,
      isFilterOpen: false,
      isSelectorOpen: false,
    };
  },
  created() {
    const earliestDate = new Date(Math.min(...eventData.map(event => new Date(event.startDate))));
    console.log(earliestDate.getFullYear());
    this.populateMonths(earliestDate);
    if(this.isLeaderEvents) {
      this.tags = [{ id: 'A', name: 'Leaders' }, ...this.getUniqueTags(eventData)];
    } else {
      this.tags = this.getUniqueTags(eventData);
    }
    this.tagOptions = this.tags.map((tag) => { return { label: tag.name + ' events', value: tag.id }; });
    this.monthEvents = this.getMonthEvents(eventData);
    const monthWithEvents = this.monthEvents.filter((month) => month.event?.length > 0);
    this.monthOptions = monthWithEvents.map(month => { return { label: month.month, value: month.name, year: month.year } });

  },
  computed: {
    isLeaderEvents() {
      const leaderEvents = eventData.filter(event => event.isLeader);
      return leaderEvents.length > 0;
    }
  },
  mounted() {
    const elem = document.getElementById('calendar-top')
    elem.scrollToBottom();
  },
  watch: {
    selectedEvents(newVal, oldVal) {
      if (newVal == "") {
        this.monthEvents = this.getMonthEvents(eventData);
        return;
      }
      let eventFilteredEvents = eventData.filter(event =>
        event.tags.some(tag => newVal.includes(tag.id))
      );
      if (newVal.includes('A')) {
        const leaderFilteredEvents = eventData.filter(event => event.isLeader === true);
        eventFilteredEvents = [...leaderFilteredEvents, ...eventFilteredEvents];
      }
      this.monthEvents = this.getMonthEvents(eventFilteredEvents);
    },
    selectedMonth(newVal, oldVal) {
      const month = newVal.split(" ")[0];
      const year = newVal.split(" ")[1];
      this.topMonth = month;
      const components = this.$el.querySelectorAll('.eventItem');
      Object.values(components).every((comp, index) => {
        if (comp.getAttribute('month') === month && comp.getAttribute('year') === year) {
          comp.scrollIntoView({ behavior: "smooth", block: "center" });
          return false;
        }
        return true;
      });
      this.isSelectorOpen = false;
    }
  },
  methods: {
    populateMonths(earliestDate) {
      const monthsArray = [];
      const currentDate = dayjs();
      const startDate = dayjs(earliestDate);

      const yearDiff = Math.abs(this.currentYear - startDate.year());
      const totalMonths = (yearDiff * 12) + (12 - this.currentMonth);

      let dateToAdd = startDate.clone();


      for (let i = 0; i <= totalMonths; i++) {
        monthsArray.push({
          month: dateToAdd.format("MMMM"),
          year: dateToAdd.format("YYYY"),
        });
        dateToAdd = dateToAdd.add(1, 'month');
        console.log(dateToAdd.year() === currentDate.year(), dateToAdd.month() === currentDate.month(), dateToAdd.year(), currentDate.year(), dateToAdd.month(), currentDate.month());
        if (dateToAdd.year() === currentDate.year() && dateToAdd.month() === currentDate.month() + 1)
          break;
      }

      this.months = monthsArray;
    },
    getMonthEvents(eventData) {
      return this.months.map(month => {
        return {
          name: month.month + " " + month.year,
          month: month.month,
          year: month.year,
          event: eventData.filter(e => dayjs(e.startDate).format("MMMM YYYY") === month.month + " " + month.year) || []
        }
      })
    },
    onScroll(ev) {
      this.scrollValue = ev.detail.scrollTop;
      const child = this.$refs.calendarList;
      this.topMonth = child.findTopVisibleComponent();
    },
    getUniqueTags(events) {
      const allTags = events
        .flatMap(event => event.tags)
        .filter(tag => tag);

      const uniqueTagsMap = new Map();

      allTags.forEach(tag => {
        if (!uniqueTagsMap.has(tag.id)) {
          uniqueTagsMap.set(tag.id, tag);
        }
      });

      return Array.from(uniqueTagsMap.values());
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  font-family: "inter";
  font-style: normal;
}
</style>