<template>
  <div>
    <BaseSwiper
      :id="baseSwiperId"
      :ref="baseSwiperId"
      v-slot="slotProps"
      :items="events"
      :options="swiperOptions"
      class="pl-[20px]"
      :class="{
        'lg:hidden': events.length < 4,
      }"
    >
      <!-- Note: thumbnail[0].url is for testing purpose -->
      <HomeTile
        class="border-[0px] rounded-lg drop-shadow-tile relative flex flex-col w-[152px] h-[152px] lg:w-full h-full bg-[#F6F5F1]"
        corner-text-class="text-xs lg:text-[16px] sm:px-[12px] font-semibold leading-[20px]"
        image-class="rounded-[10px]"
        :image-src="slotProps.item.thumbnail[0].url"
        :corner-text="slotProps.item.cornerText"
        text-wrapper-class="min-h-[48px] text-left pt-[8px] bg-[#F6F5F1]"
        @click="navigate(slotProps.item.link)"
      >
        <p class="text-[8px] leading-[16px] font-medium text-[#787878]">
          {{ slotProps.item.subtitle }}
        </p>
        <h1 class="text-[16px] leading-[16px] font-medium">
          {{ slotProps.item.title }}
        </h1>
      </HomeTile>
    </BaseSwiper>

    <div
      v-if="events.length < 4"
      class="hidden mt-4 lg:flex lg:items-stretch w-[152px] h-[152px] lg:w-full h-full bg-[#F6F5F1]"
    >
      <!-- Note: thumbnail[0].url is for testing purpose -->
      <HomeTile
        v-for="(item, i) in events"
        :key="i"
        class="desktop-slide flex-1 border-[0px] rounded-lg drop-shadow-tile relative w-[200px] lg:w-full h-full"
        :class="{
          'tablet-slide': events.length < 4,
          'ml-[20px]': i >= 1,
        }"
        corner-text-class="text-xs lg:text-[16px] sm:px-[12px] font-semibold leading-[20px]"
        image-class="rounded-[10px]"
        :image-src="item.thumbnail[0].url"
        :corner-text="item.cornerText"
        text-wrapper-class="text-left pt-[8px] bg-[#F6F5F1]"
        @click="navigate(item.link, item.title)"
      >
        <p class="text-[8px] leading-[16px] font-medium text-[#787878]">
          {{ item.subtitle }}
        </p>
        <h1 class="text-[16px] leading-[16px] font-medium">
          {{ item.title }}
        </h1>
      </HomeTile>
    </div>
  </div>
</template>

<script>
import { Browser } from "@capacitor/browser";
import BaseSwiper from "@/components/design/BaseSwiper.vue";
import HomeTile from "@/components/design/HomeTile.vue";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { isPlatform } from "@ionic/vue";

export default {
  components: {
    BaseSwiper,
    HomeTile,
  },
  props: {
    baseSwiperId: {
      type: String,
    },
    events: {
      type: Array,
      default: () => [],
    },
    swiperOptions: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    breakpoints() {
      return {
        641: {
          slidesPerView: 2,
          centeredSlides: false,
        },
        1024: {
          slidesPerView: 3,
          centeredSlides: false,
        },
        1366: {
          slidesPerView: 4,
          centeredSlides: false,
        },
      };
    },
  },
  methods: {
    update() {
      const swiper = this.$refs[this.baseSwiperId];
      swiper.update();
    },
    navigate(link, title) {
      const isNative = isPlatform("android") || isPlatform("ios");
      const isNativeBrowser = isPlatform("mobileweb");
      if (isNative && !isNativeBrowser) {
        FirebaseAnalytics.logEvent({
          name: "click_button",
          params: {
            button_name: title,
            page: "HomePage",
          },
        });
      }
      if (link.isExternal) {
        const url = link.URL;
        Browser.open({ url });
      } else {
        const subString = "heartofgodchurch.org/";
        const index = link.URL.indexOf(subString);
        const url = link.URL.slice(index + subString.length, link.URL.length);
        this.$router.push(`${url}`);
      }
    },
  },
};
</script>

<style scoped>
@media (min-width: 1024px) {
  .tablet-slide {
    max-width: calc(33.3% - 15px);
  }
}

@media (min-width: 1366px) {
  .desktop-slide {
    max-width: calc(25% - 15px);
  }
}
</style>

<style>
.swiper-pagination {
  display: none !important;
}
</style>
