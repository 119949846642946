<template>
  <div class="flex flex-row flex-wrap gap-[12px]">
    <div
      v-for="(name, index) in serviceNames"
      class="h-[40px] text-center py-[8px] px-[12px] border rounded-[100px] gap-2 cursor-pointer"
      :class="{
        'bg-[#FFE34E] border-[#FCEB8F] text-[##191817]': isSelected(index),
        'bg-transparent text-white': !isSelected(index),
      }"
      :key="index"
      @click="selectService(name, index)"
    >
      <p
        class="font-[Inter] text-[16px] leading-[24px] font-medium text-center text-nowrap"
      >
        {{ name }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { isPlatform } from "@ionic/vue";

export default {
  computed: {
    ...mapGetters("serviceHighlights", [
      "serviceNames",
      "selectedServiceIndex",
    ]),
  },
  methods: {
    selectService(name, index) {
      const isNative = isPlatform("android") || isPlatform("ios");
      const isNativeBrowser = isPlatform("mobileweb");
      if (isNative && !isNativeBrowser) {
        FirebaseAnalytics.logEvent({
          name: "click_button",
          parameters: {
            button_name: name,
            page: "ServiceHighlights",
          },
        });
      }
      this.$store.dispatch(
        "serviceHighlights/updateSelectedServiceIndex",
        index
      );
    },
    isSelected(index) {
      return this.selectedServiceIndex === index;
    },
  },
};
</script>
