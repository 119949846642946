<template>
    <div class="mt-2 flex flex-wrap">
        <div v-if="tags.length > 0" class="flex">
            <div v-for="tag in tags" :key="tag.name">
                <div
                v-if="tag.isTag"
                class="box-border h-7 px-[8px] py-[4px] mr-2 rounded-[16px]"
                :class="[
                    {
                    'bg-[#C3C2C1] text-[#FFFFFF]': hasPast,
                    'bg-[#FED970] text-[#8C6D17]': tag.name === '#HOGC21' && !hasPast,
                    'bg-[#D3E5EF] text-[#373E49]': tag.name === 'No FOMO' && !hasPast,
                    'bg-[#ECDDFF] text-[#6200DF]': tag.name === 'Youth' && !hasPast,
                    'bg-[#CCE0FF] text-[#0013BF]': tag.name === 'UR' && !hasPast,
                    'bg-[#E0F5ED] text-[#00795C]': tag.name === 'Pre-Uni' && !hasPast,
                    'bg-[#FFE1DF] text-[#FF3D22]': tag.name === 'Students' && !hasPast,
                    'bg-[#C5C7FA] text-[#3F44D9]': tag.name === 'Global' && !hasPast,
                    }
                ]"
                >
                    <p class="text-[12px] font-semibold leading-[20px] text-center px-1">
                        {{ tag.name }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  props: {
    hasPast: {
      type: Boolean,
      default: false,
    },
    tags: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      pastClass: 'bg-[#D3D8DE] text-[#7C8A9C]',
    }
  },
}
</script>
<style scoped>
* {
  font-family: 'inter';
  font-style: normal;
}
</style>