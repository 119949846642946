<template>
  <div
    class="flex items-center justify-center w-full h-[56px] border-white border-[2px] rounded-[16px]"
    @click="navigate"
  >
    <div class="flex flex-row items-center space-x-[10px] padding-[16px]">
      <img
        src="../../../public/assets/icons/service-highlights/watch-service-replay.svg"
        class="w-[16px] h-[16px]"
        alt="watch service replay icon"
        @click="navigate"
      />
      <p
        class="font-[Inter] font-bold text-[#E5E5E5] text-[16px] leading-[24px]"
      >
        Watch Service Replay
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("serviceHighlights", ["currentService"]),
  },
  methods: {
    navigate() {
      this.$router.push({
        path: `/service-replay/${this.currentService.serviceReplayId}`,
      });
    },
  },
};
</script>
