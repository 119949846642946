<template>
  <div class="shadow-md rounded-lg mx-5 px-4 py-3 mt-4"
    :class="isLightMode ? 'bg-white text-black' : 'bg-[#2B2926] text-white'">
    <div v-if="dateTime.length > 1" class="text-[24px] font-bold mb-4">
      {{ eventName }}
    </div>
    <div class="flex flex-col gap-y-6">
      <div v-for="(item, index) in dateTime" :key="index" class="flex flex-row">
        <div class="col-span-3 text-center rounded-t-md w-[70px]">
          <div v-if="item.fromDate === item.toDate"
            class="rounded-t-md text-[12px] py-1 px-1 font-semibold"
            :class="isLightMode ? 'bg-[#FEC629]' : 'bg-[#78634F]'">
            {{ getDay(item.fromDate) }}
          </div>
          <div v-else class="rounded-t-md text-[12px] py-1 px-1 font-semibold"
            :class="isLightMode ? 'bg-[#FEC629]' : 'bg-[#78634F]'">
            {{ getDay(item.fromDate) }} - {{ getDay(item.toDate) }}
          </div>

          <div
            class="rounded-b-md text-[16px] font-semibold leading-4 py-1 px-1 h-[40px] flex justify-center items-center whitespace-pre uppercase"
            :class="isLightMode ? 'bg-[#ECEBEB]' : 'bg-[#3C3A36]'">
            {{ displayDate(item.fromDate, item.toDate) }}
          </div>
        </div>
        <div class="flex flex-col">
          <div v-if="dateTime.length === 1" class="text-[24px] font-bold ml-[16px]">
            {{ eventName }}
          </div>
          <div v-for="(range, index) in item.timeRange" :key="index" class="text-[16px] font-bold uppercase ml-[16px]"
            v-html="formatDayTime(range.dayTime)">
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4 text-center font-bold text-sm grid gap-4"
      :class="sharingUrl && downloadAsset ? 'grid-cols-2' : 'grid-cols-1'">
      <div v-if="sharingUrl" @click="share()"
        class="rounded-full inline-block w-full border-2 py-2 flex justify-center gap-x-2"
        :class="isLightMode ? 'border-[#21201E]' : 'border-[#ECEBEB]'">
        <img src="../../../public/assets/icons/event-page/share-icon.svg" :class="{ 'invert-icon': !isLightMode }" />
        <div>Share</div>
      </div>
      <div v-if="downloadAsset" @click="downloadPhoto()"
        class="rounded-full inline-block w-full border-2 py-2 flex justify-center gap-x-2"
        :class="isLightMode ? 'border-[#21201E]' : 'border-[#ECEBEB]'">
        <img src="../../../public/assets/icons/event-page/download-icon.svg" :class="{ 'invert-icon': !isLightMode }" />
        <div>Download</div>
      </div>
      <Toast />
    </div>
  </div>
</template>
<script>
import { Share } from "@capacitor/share";
import { Media } from "@capacitor-community/media";
import { mapState } from "vuex";
import Toast from "@/components/common/Toast.vue";
export default {
  name: "EventDetailsSection",
  components: {
    Toast,
  },
  props: {
    eventName: {
      type: String,
      default: "",
    },
    downloadAsset: {
      type: String,
      default: "",
    },
    sharingUrl: {
      type: String,
      default: "",
    },
    dateTime: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState("eventPage", ["isLightMode"]),
  },
  methods: {
    getDay(date) {
      const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
      return days[new Date(date).getDay()];
    },
    displayDate(fromDate, toDate) {
      if (fromDate === toDate) {
        return `${new Date(fromDate).getDate()}\n${new Date(
          fromDate
        ).toLocaleString("default", { month: "short" })}`;
      }
      return `${new Date(fromDate).getDate()} - ${new Date(
        toDate
      ).getDate()}\n${new Date(toDate).toLocaleString("default", {
        month: "short",
      })}`;
    },
    formatDayTime(dayTime) {
      return dayTime.replace(/\n/g, '<br>');
    },
    // displayTiming(from_time, to_time) {
    //   const fromTime = from_time.split(":");
    //   let fromTimeHour = fromTime[0];
    //   const fromTimeMinute = fromTime[1];
    //   const fromPeriod = fromTimeHour >= 12 ? "PM" : "AM";
    //   const toTime = to_time.split(":");
    //   let toTimeHour = toTime[0];
    //   const toTimeMinute = toTime[1];
    //   const toPeriod = toTimeHour >= 12 ? "PM" : "AM";
    //   if (fromTimeHour > 12) {
    //     fromTimeHour = fromTimeHour - 12;
    //   }
    //   if (toTimeHour > 12) {
    //     toTimeHour = toTimeHour - 12;
    //   }
    //   return `${fromTimeHour}.${fromTimeMinute}${fromPeriod} - ${toTimeHour}.${toTimeMinute}${toPeriod}`;
    // },
    async share() {
      await Share.share({
        title: this.eventName,
        url: this.sharingUrl,
      });
    },
    async downloadPhoto() {
      let opts = { path: this.downloadAsset };
      try {
        await Media.savePhoto(opts);
        this.$store.dispatch("toast/show", "Successfully downloaded image");
      } catch (e) {
        this.$store.dispatch("toast/error", "Failed to download image");
      }
    },
  },
};
</script>

<style>
.invert-icon {
  filter: invert(6%) sepia(6%) saturate(972%) hue-rotate(2deg) brightness(97%) contrast(84%);
}
</style>
