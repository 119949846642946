<template>
  <div class="font-normal text-base leading-6" :class="hasPast ? pastClass : futureClass">
    <div v-if="eventLocation" class="flex items-center">
      <img class="h-full" :src="eventLocationIcon" alt="location icon" />
      <p class="ml-1">
        {{ eventLocation }}
      </p>
    </div>
    <div v-if="eventTime" class="flex items-center">
      <img class="h-full" :src="eventTimeIcon" alt="time icon" />
      <p v-if="typeof (eventTime) == 'string'" class="ml-1">
        {{ eventTime }}
      </p>
      <div v-if="Array.isArray(eventTime)">
        <p v-for="(time, index) in eventTime" :key="index" class="ml-1">
          {{ time }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hasPast: {
      type: Boolean,
      default: false,
    },
    eventTime: {
      type: [String, Array],
      default: null,
    },
    eventLocation: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      pastClass: "text-[#7C8A9C]",
      futureClass: "text-[#4D5766]",
    };
  },
  computed: {
    eventTimeIcon() {
      return this.hasPast
        ? require("../../../../public/assets/icons/calendar/event-time-past.svg")
        : require("../../../../public/assets/icons/calendar/event-time-future.svg");
    },
    eventLocationIcon() {
      return this.hasPast
        ? require("../../../../public/assets/icons/calendar/event-location-past.svg")
        : require("../../../../public/assets/icons/calendar/event-location-future.svg");
    },
  },
};
</script>

<style scoped>
* {
  font-family: "inter";
  font-style: normal;
}
</style>
