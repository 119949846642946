<template>
  <master-layout>
    <QrCode class="relative bg-[#f6f4f0] h-[calc(100vh-69px)]" />
  </master-layout>
</template>

<script>
import QrCode from "@/components/HomePage/QrCode";

export default {
  components: { QrCode },
  async ionViewWillEnter() {
    await this.$store.dispatch("updates/getUpdates", "all");
  },
};
</script>
