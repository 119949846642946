<template>
    <div class="checkbox-group">
        <div v-for="option in options" :key="option.value"
            :class="modelValue.includes(option.value) ? 'bg-[#E8DCC766] border-[2px]' : ''"
            class="flex items-center cursor-pointer mb-[8px] p-[12px] rounded-[10px] border-[1px] border-solid border-[#A5A4A3]"
            @click="toggleOption(option.value)">
            <div class="w-[20px] h-[20px] flex items-center justify-center mr-3">
                <img v-if="modelValue.includes(option.value)" src="../../../public/assets/icons/calendar/checkboxfill.svg" alt="" />
                <img v-if="!modelValue.includes(option.value)" src="../../../public/assets/icons/calendar/checkbox.svg" alt="" />
            </div>
            <label :class="modelValue.includes(option.value) ? 'font-[800]' : ''" class="text-[16px] leading-[24px] text-[#3C3A36] font-medium select-none">
                {{ option.label }}
            </label>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    modelValue: {
        type: Array,
        required: true
    },
    options: {
        type: Array,
        required: true,
        validator: (value) => {
            return value.every(option =>
                option.hasOwnProperty('value') &&
                option.hasOwnProperty('label')
            );
        }
    }
});

const emit = defineEmits(['update:modelValue']);

const toggleOption = (value) => {
    const newValue = [...props.modelValue];
    const index = newValue.indexOf(value);

    if (index === -1) {
        newValue.push(value);
    } else {
        newValue.splice(index, 1);
    }

    emit('update:modelValue', newValue);
};
</script>