<template>
    <div class="checkbox-group">
      <div v-for="(option, index) in options" :key="option.value">
        <h3 :class="index!== 0 && options[index - 1].year != option.year ? 'pt-[16px]' : ''" class="font-extrabold text-[16px] text-[#191817] leading-24 pb-[8px]" v-if="option.year && (index!== 0 ? options[index - 1].year != option.year : true)">Year {{ option.year }}</h3>
        <div
            :class="modelValue === option.value ? 'bg-[#E8DCC766] border-[2px]' : ''"
            class="flex items-center cursor-pointer mb-[8px] p-[12px] rounded-[10px] border-[1px] border-solid border-[#A5A4A3]"
            @click="selectOption(option.value)">
            <div class="w-[20px] h-[20px] flex items-center justify-center mr-3">
                <img v-if="modelValue === option.value" src="../../../public/assets/icons/calendar/radio-button-filled.svg" alt="" />
                <img v-if="modelValue !== option.value" src="../../../public/assets/icons/calendar/radio-button.svg" alt="" />
            </div>
            <label :class="modelValue === option.value ? 'font-bold' : 'font-medium'" class="text-[16px] leading-[24px] text-[#3C3A36] select-none">
                {{ option.label }}
            </label>
        </div>
      </div>
    </div>
</template>

<script setup>
const props = defineProps({
  modelValue: {
    type: String,
    required: true
  },
  options: {
    type: Array,
    required: true,
    validator: (value) => {
      return value.every(option => 
        option.hasOwnProperty('value') && 
        option.hasOwnProperty('label')
      );
    }
  }
});

const emit = defineEmits(['update:modelValue']);

const selectOption = (value) => {
  emit('update:modelValue', value);
};
</script>