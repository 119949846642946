<template>
    <div ref="header" class="fixed top-0 z-50 bg-[#F2EFED] transition-transform duration-300 w-full"
        :style="{ transform: `translateY(${headerTransform}px)` }">
        <div class="flex items-center justify-between px-[24px] pt-[16px] pb-[8px]">
            <slot name="left">
            </slot>
            <slot name="center">
                <h1 class="text-lg font-semibold">{{ title }}</h1>
            </slot>
            <slot name="right"></slot>
        </div>
        <slot name="bottom"></slot>
    </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';

const props = defineProps({
    title: {
        type: String,
        default: ''
    },
    peekHeight: {
        type: Number,
        default: 10
    },
    headerHeight: {
        type: Number,
        default: 48
    },
    enablePeek: {
        type: Boolean,
        default: true
    },
    scrollElementId: {
        type: String,
    },
});

const header = ref(null);
const headerTransform = ref(0);
let lastScrollTop = 0;

const onScroll = async (event) => {
    if (!props.enablePeek) return;

    const scrollElement = await (document.getElementById(props.scrollElementId))?.getScrollElement();

    const currentScroll = scrollElement ? scrollElement.scrollTop : window.scrollY;
    const headerHeight = header.value?.offsetHeight || props.headerHeight;
    // Scrolling down
    if (currentScroll > lastScrollTop) {
        headerTransform.value = -(headerHeight - props.peekHeight);
    }
    // Scrolling up
    else {
        headerTransform.value = 0;
    }

    lastScrollTop = currentScroll;
};

// Add debouncing to prevent excessive calculations
const debounce = (fn, delay) => {
    let timeoutId;
    return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => fn(...args), delay);
    };
};

const debouncedScroll = debounce(onScroll, 10);

onMounted(() => {
    console.log('mounted');
    document.getElementById(props.scrollElementId).getScrollElement().then(scrollElement => {
        !scrollElement ? window.addEventListener('scroll', debouncedScroll, { passive: true }) : scrollElement.addEventListener('scroll', debouncedScroll, { passive: true });
    });
});

onUnmounted(() => {
    document.getElementById(props.scrollElementId).getScrollElement().then(scrollElement => {
        !scrollElement ? window.removeEventListener('scroll', debouncedScroll): scrollElement.removeEventListener('scroll', debouncedScroll);
    })
});
</script>