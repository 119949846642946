<template>
  <birthday-layout
    @on-scroll="handleScroll"
    :enable-scroll-event="true"
    id="board-pinned"
  >
    <div>
      <BoardContent
        ref="boardContent"
        class="pt-[152px] md:pt-[200px]"
        :is-pinned-messages="true"
        @set-debounce="setDebounce"
      />
    </div>
  </birthday-layout>
</template>

<script>
import BoardContent from "@/components/Board/Main/BoardContent";
import BirthdayLayout from "@/components/BirthdayLayout.vue";

export default {
  data() {
    return {
      debounce: false,
    };
  },
  components: {
    BoardContent,
    BirthdayLayout,
  },
  methods: {
    async handleScroll($event) {
      if (this.debounce) return;
      const scrollElement = await $event.target.getScrollElement();
      if (
        scrollElement.clientHeight + scrollElement.scrollTop >=
        (scrollElement.scrollHeight || scrollElement.clientHeight)
      ) {
        this.debounce = true;
        await this.$refs.boardContent.loadMorePosts();
      }
    },
    setDebounce(value) {
      this.debounce = value;
    },
  },
};
</script>
