<template>
    <master-layout>
      <div v-if="isLoading" class="w-screen h-screen flex items-center justify-center">
          <Loading />
      </div>
      <div
        v-else
        class="h-[calc(var(--app-height)-(88px))] lg:flex-1 pt-[24px] pb-[123px] md:pb-[138px] md:pt-[32px] md:px-[56px] px-[24px]"
        >
        <header class="z-10 mb-[24px]">
          <img
            src="../../../public/assets/icons/chevron-left-white.svg"
            class="flex items-center rounded-full"
            alt="Back icon"
            @click="back"
          />
        </header>
        <section>
          <h1
            class="text-[32px] pb-[65px] leading-[40px] sm:text-[48px] sm:leading-[64px] font-bold mb-[8px] sm:mb-[4px] text-[#0B0C0F]"
          >
            Service replay
          </h1>
          <p class="text-[16px] leading-[24px] text-[#21252C]">
            Please enter passcode
          </p>
        
  
        <div
          class="flex items-center px-[8px] py-[8px] gap-[10px] rounded-[8px] border-[1px] border-solid border-[#CACACE] bg-[#FFFFFF] h-[56px]"
        >
          <input
            v-model="passcodeField"
            type="text"
            placeholder="Passcode"
          />
          <img
            src="@/../public/assets/icons/enter-arrow.svg"
            class="cursor-pointer self-end"
            @click="checkPasscode"
          />
        </div>
        <p v-if="passcodeFailed" class="text-[12px] leading-[24px] text-[#ff0000] pt-[5px]">
          Ooops. Sorry wrong code!
        </p>
        <p class="text-[16px] leading-[24px] text-[#21252C] pt-[20px]">
          Need help? Ask your Connect Group Leader or drop us a text at ____ 😎
        </p>
      </section>
      </div>
    </master-layout>
  </template>
  
  <script>
  import { mapState } from "vuex";
  import Loading from "@/components/common/LoadingState.vue";
  
  export default {
    data() {
      return {
        passcodeFailed: false,
        passcodeField: '',
      };
    },
    components: {
      Loading,
    },
    computed: {
      ...mapState("serviceReplay", {
        isLoading: (state) => state.isLoading,
        passcode: (state) => state.passcode,
        defaultReplayId: (state) => state.defaultReplayId,
      }),
    },
    async ionViewWillEnter() {
        //Need to get a CMS entry of general passcode
        await this.$store.dispatch("serviceReplay/fetchPasscode");
        await this.$store.dispatch("serviceReplay/fetchWeekends");
      },
    methods: {
      back () {
        this.$router.push('/home')
      },
      async checkPasscode () {
        if (this.passcodeField == this.passcode) {
          await this.$store.dispatch("serviceReplay/passwordEntered")
          this.$router.push('/service-replay/' + this.defaultReplayId)
        }
        else {
          this.passcodeFailed = true
        }
      },
    },
  };
  </script>
  
  <style scoped>
  input {
    /* Body 2 */
    background-color: #FFFFFF;
    font-family: Proxima Nova;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    border: none;
    width: 100%;
  }

  input:focus {
    outline: none;
  }
  </style>
  