<template>
  <master-layout>
    <div
      class="flex flex-row justify-between w-full px-5 pt-4 pb-2 bg-[#F6F5F1]"
      :class="{ '!pt-[60px]': isIos() }"
    >
      <div>
        <router-link to="/home"
          ><img
            class="w-[32px] h-[32px]"
            src="../../../public/assets/icons/back.svg"
        /></router-link>
      </div>
      <div></div>
    </div>
    <div
      class="text-[24px] leading-[39px] w-full py-4 px-6 font-weight-600 text-[#15104D] bg-[#F6F5F1]"
    >
      All
    </div>
    <div class="flex flex-col gap-4 w-full h-full px-6 bg-[#F6F5F1]">
      <div
        v-for="(types, index) in iconTypes"
        :key="index"
        class="rounded-[16px] bg-[#FFFFFF] p-4"
      >
        <div
          class="text-[14px] leading-[20px] font-weight-500 text-[#3C3A36] mb-2"
        >
          {{ types.name }}
        </div>
        <div class="flex flex-row flex-wrap gap-2">
          <div v-for="(icon, i) in allPageIcons" :key="i">
            <div
              v-if="icon.iconType.name === types.name"
              class="flex flex-col w-[92px]"
            >
              <router-link :to="icon.URL">
                <img class="w-[48px] h-[48px] mx-auto" :src="icon.icon.url" />
                <div
                  class="text-[12px] leading-[16px] font-normal text-[#161616] text-center"
                >
                  {{ icon.name }}
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </master-layout>
</template>
<script>
import { mapState } from "vuex";
import { isIosNativeOnly } from "@/utils/platform-check";

export default {
  computed: {
    ...mapState("allpage", ["allPageIcons", "iconTypes"]),
  },
  async ionViewWillEnter() {
    await this.$store.dispatch("allpage/getAllPageIcons"); // trigger get all page icons
    await this.$store.dispatch("updates/getUpdates", "all");
  },
  methods: {
    isIos() {
      return isIosNativeOnly();
    },
  },
};
</script>
<style scoped>
div:empty {
  display: none;
}
</style>
