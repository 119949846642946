<template>
  <master-layout :enable-scroll-event="true" id="updatesOverview">
    <div class="flex flex-col min-h-screen bg-[#EAE8E5]">
      <div
        class="mx-5 md:mx-[32px] lg:ml-[56px] pt-4 mb-[20px] md:mt-[40px]"
        :class="{ 'pt-[60px]': isIos() }"
      >
        <router-link to="/home"
          ><img src="../../../public/assets/icons/back.svg"
        /></router-link>
      </div>
      <div class="text-[32px] leading-[40px] text-[#433D31] font-black mx-5 mb-6">
        Notifications
      </div>
      <BaseSwiper
        id="update-type-swiper"
        ref="update-type-swiper"
        v-slot="slotProps"
        :items="updateTypes"
        :options="swiperOptions"
        class="mt-1 mx-5 mb-6"
        :class="{
          'lg:hidden': updateTypes.length < 4,
        }"
      >
        <BaseButton
          class="button border-4 border-solid object-center hover:bg-sky-500 hover:border-sky-500 hover:!text-white whitespace-nowrap"
          :class="activeStyle(slotProps.item.value)"
          :style="{
            'font-size': '12px',
          }"
          @click="filterUpdates(slotProps.item.value)"
          >{{ slotProps.item.name }}
          {{ unreadCount(slotProps.item.value) }}</BaseButton
        >
      </BaseSwiper>

      <section>
        <div v-if="todaysUpdates.length > 0">
          <div
            class="text-[16px] leading-[20px] mb-[4px] mx-5 md:mx-[32px] lg:mx-[56px] font-bold text-[#3C3A36] md:mb-[32px]"
          >
            Today
          </div>
          <UpdatesCard
            v-for="update in todaysUpdates"
            :id="`card${update.id}`"
            :key="update.id"
            :update="update"
            :status="update.isNew"
            class="scroll-margin cursor-pointer"
            @click="openUpdate(update)"
          ></UpdatesCard>
        </div>
        <div v-if="earlierUpdates.length > 0">
          <div
            class="text-[16px] leading-[20px] mb-[4px] mx-5 md:mx-[32px] lg:mx-[56px] font-bold text-[#3C3A36] md:mb-[32px]"
          >
            Earlier
          </div>
          <UpdatesCard
            v-for="update in earlierUpdates"
            :id="`card${update.id}`"
            :key="update.id"
            :update="update"
            :status="update.isNew"
            class="scroll-margin cursor-pointer"
            @click="openUpdate(update)"
          ></UpdatesCard>
        </div>
      </section>
      <div class="my-8 md:my-10 lg:my-12">
        <div class="flex flex-col cursor-pointer" @click="backToTop()">
          <div
            class="flex flex-col flex-wrap justfiy-center mb-[24px] space-y-2"
          >
            <img src="../../../public/assets/icons/back-to-top-icon-blue.svg" />
            <p
              class="text-center text-[16px] leading-[24px] font-aktiv-grotesk-trial hover:underline"
            >
              Back to top
            </p>
          </div>
        </div>
      </div>
    </div>
  </master-layout>
</template>

<script>
// import moment from "moment";
import { mapState } from "vuex";
import UpdatesCard from "../../components/UpdatesPage/UpdatesCard";
// import axios from "axios";
import { isIosNativeOnly } from "@/utils/platform-check";
import BaseSwiper from "../../components/design/BaseSwiper.vue";
import BaseButton from "../../components/design/BaseButton.vue";

export default {
  name: "UpdatesPage",
  components: { UpdatesCard, BaseSwiper, BaseButton },
  data() {
    return {
      // updates: [],
      // hash: null,
      swiperOptions: {
        centeredSlides: false,
        slidesPerView: "auto",
        grabCursor: true,
        pagination: {
          el: ".swiper-pagination",
          dynamicBullets: true,
          clickable: true,
        },
        hasNavigation: true,
      },
    };
  },
  computed: {
    ...mapState("updates", [
      "updates",
      "earlierUpdates",
      "todaysUpdates",
      "updateTypes",
      "activeButton",
    ]),
  },
  async ionViewWillEnter() {
    await this.$store.dispatch("updates/getUpdates", "all");
    this.filterUpdates(this.activeButton);
    const swiper = this.$refs["update-type-swiper"];
    swiper.update();
    // axios
    //   .get(process.env.VUE_APP_API_URL + "/cms/announcements/get", {
    //     headers: {
    //       Authorization: `Bearer ${this.token}`,
    //     },
    //   })
    //   .then((res) => {
    //     res.data
    //       .sort((a, b) => moment(b.postedTime).diff(a.postedTime))
    //       .forEach((item) => {
    //         this.announcements.push(item);
    //       });
    //     this.$nextTick(() => {
    //       if (this.$route.hash) {
    //         const el = document.querySelector(this.$route.hash);
    //         el && el.scrollIntoView({ behavior: "smooth" });
    //         this.hash = this.$route.hash.slice(-1);
    //       }
    //     });
    //   });
  },
  async ionViewDidLeave() {
    // await this.$store.commit("updates/setUnreadCount", 0);
  },
  updated() {
    this.$nextTick(() => {
      if (this.$route.hash) {
        "#card" + updates.id + " a";
      }
    });
  },
  methods: {
    isIos() {
      return isIosNativeOnly();
    },
    async filterUpdates(type) {
      await this.$store.dispatch("updates/getUpdates", type);
      await this.$store.commit("updates/setActiveButton", type);
    },
    activeStyle(name) {
      if (this.activeButton === name) {
        return "bg-[#3C3A36] !text-white";
      } else {
        return "bg-grey-10 !text-black";
      }
    },
    async openUpdate(update) {
      await this.$store.dispatch("updates/markReadUpdate", update.id);
      update.slug
        ? this.$router.push({ path: `/event-page/${update.slug}` })
        : "";
    },
    unreadCount(type) {
      let count = 0;
      if (type === "all") {
        this.updates.forEach((update) => {
          if (update.isNew) {
            count++;
          }
        });
        if (count > 0) {
          return "(" + count + ")";
        }
        return "";
      } else {
        this.updates.forEach((update) => {
          if (update.updateType.updateType === type && update.isNew) {
            count++;
          }
        });
        if (count > 0) {
          return "(" + count + ")";
        }
        return "";
      }
    },
    backToTop() {
      const elem = document.getElementById("updatesOverview");
      elem.scrollToTop(1000);
    },
  },
};
</script>

<style scoped>
.scroll-margin {
  scroll-margin-top: 48px;
}
::v-deep .swiper-container {
  padding-bottom: 0;
}
</style>
