<template>
  <empty-layout>
    <div class="min-h-screen">
      <div>
        <img
          class="object-cover w-full"
          :src="currentService.thumbnailSrc"
          alt="Service Banner"
        />
        <img
          src="../../../public/assets/icons/service-highlights/navigate-back.svg"
          class="absolute top-[52px] left-[20px] w-[32px] h-[32px]"
          alt="Back icon"
          @click="back"
        />
      </div>
      <div class="px-[20px] bg-[#3C3A36] pb-[64px]">
        <h1
          class="font-[Inter] text-[24px] leading-[40px] text-white font-black"
        >
          {{ serviceDates }}
        </h1>
        <ServiceTabs class="mt-[16px]" />
        <Announcements
          v-if="currentService.announcements.length > 0"
          class="mt-[40px]"
          :announcements="currentService.announcements"
        />
        <ServiceReplayButton v-if="showReplay" class="mt-[40px]" />
        <DownloadablesSlider
          v-if="currentService.downloadables.length > 0"
          class="mt-[40px]"
          ref="service-highlights-page-downloadables"
          baseSwiperId="service-highlights-page-downloadables"
          :items="currentService.downloadables"
        />
      </div>
    </div>
  </empty-layout>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Announcements from "@/components/ServiceHighlightsPage/Announcements.vue";
import DownloadablesSlider from "@/components/ServiceHighlightsPage/DownloadablesSlider.vue";
import ServiceReplayButton from "@/components/ServiceHighlightsPage/ServiceReplayButton.vue";
import ServiceTabs from "@/components/ServiceHighlightsPage/ServiceTabs.vue";

export default {
  data() {
    return {
      showReplay: false,
    };
  },
  components: {
    ServiceTabs,
    Announcements,
    DownloadablesSlider,
    ServiceReplayButton,
  },
  computed: {
    ...mapGetters("serviceHighlights", ["serviceDates", "currentService"]),
    ...mapState("profile", {
      pastoralStatus: (state) => state.memberCgZones[0].role.name,
    }),
  },
  async mounted() {
    try {
      await this.$store.dispatch("serviceHighlights/fetchPage");
      await this.$store.dispatch("profile/fetchProfile"),
        this.checkPastoralStatus();
    } catch (error) {
      console.error("Error fetching service highlights:", error);
    }
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    checkPastoralStatus() {
      if (this.pastoralStatus == "V" || this.pastoralStatus == "N") {
        this.showReplay = false;
      } else {
        this.showReplay = true;
      }
    },
  },
};
</script>
