import axios from "axios";

const INITIAL_STATE = {
  isLoading: false,
  weekends: [],
  serviceReplay: {},
  passcode: '',
  defaultReplayId: '',
  hasEnteredPassword: false
}

const state = () => INITIAL_STATE

const mutations = {
  setIsLoading(state, isLoading) {
    state.isServiceReplayLoading = isLoading
  },
  setWeekends(state, weekends) {
    state.weekends = weekends
  },
  setServiceReplay(state, newServiceReplay) {
    state.serviceReplay = newServiceReplay
  },
  setPasscode(state, passcode) {
    state.passcode = passcode
  },
  setDefaultReplayId(state, id) {
    state.defaultReplayId = id
  },
  setEnteredPassword(state, entered) {
    state.hasEnteredPassword = entered
  }
}

const actions = {
  async fetchOverview({ state, commit, dispatch, rootState }) {
    try {
      commit('setIsLoading', true)
      const accessToken = rootState.user.token;
      const { data } = await axios.get(
        process.env.VUE_APP_API_URL + '/cms/service-replay/overview',
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      commit('setOverview', data)
      commit('setIsLoading', false)
    } catch (e) {
      dispatch('toast/error', 'Failed to fetch service replay overview', { root: true })
    }
  },
  async fetchPasscode({ state, commit, dispatch, rootState }) {
    try {
      commit('setIsLoading', true)
      const accessToken = rootState.user.token;
      const { data } = await axios.get(
        process.env.VUE_APP_API_URL + '/cms/service-replay/passcode',
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      commit('setPasscode', data.Passcode)
      commit('setIsLoading', false)
    } catch (e) {
      dispatch('toast/error', 'Failed to fetch service replay passcode', { root: true })
    }
  },
  async fetchWeekends({ state, commit, dispatch, rootState }) {
    try {
      commit('setIsLoading', true)
      const accessToken = rootState.user.token;
      const { data } = await axios.get(
        process.env.VUE_APP_API_URL + '/cms/service-replay/weekend',
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      commit('setDefaultReplayId', data[0].hogc_one_replays[0].id)
      commit('setWeekends', data)
      commit('setIsLoading', false)
    } catch (e) {
      dispatch('toast/error', 'Failed to fetch service replay passcode', { root: true })
    }
  },
  async fetchServiceReplayById({ state, commit, dispatch, rootState }, { serviceReplayId }) {
    try {
      if (state["serviceReplays"][serviceReplayId]) {
        return
      }
      commit('setIsLoading', true)
      const accessToken = rootState.user.token;
      const { data } = await axios.get(
        process.env.VUE_APP_API_URL + '/cms/service-replay/' + serviceReplayId,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      commit('addServiceReplay', data)
      commit('setIsLoading', false)
    } catch (e) {
      dispatch('toast/error', 'Failed to fetch service replay overview', { root: true })
    }
  },
  async passwordEntered({ commit, dispatch }) {
    try {
      commit('setEnteredPassword', true)
    }
    catch {
      dispatch('toast/error', 'Failed to update password status', { root: true })
    }
  }
}

const getters = {
  isLoading(state) {
    return state.isLoading
  },
  overview(state) {
    return state.overview
  },
  serviceReplays(state) {
    return state.serviceReplays
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
