<template>
    <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0"
        enter-to-class="opacity-100" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100"
        leave-to-class="opacity-0">
        <div v-if="modelValue" class="fixed inset-0 z-50 overflow-y-auto">
            <div class="fixed inset-0 bg-black bg-opacity-50" @click="close"></div>
            <div class="flex min-h-full items-center justify-center p-4">
                <div class="relative w-full max-w-md transform overflow-hidden rounded-[10px] bg-[#F6F4F0] p-6 shadow-xl transition-all"
                    @click.stop>
                    <!-- Close button -->
                    <button class="absolute right-[24px] top-[25px] text-gray-400 hover:text-gray-500" @click="close">
                        <CloseIcon />
                    </button>

                    <!-- Header -->
                    <div class="mb-[24px]">
                        <h3 class="font-black text-[20px] text-[#2B2926] leading-20">
                            <slot name="header"></slot>
                        </h3>
                    </div>

                    <!-- Content -->
                    <div class="mt-2">
                        <slot></slot>
                    </div>

                    <!-- Footer -->
                    <div class="mt-6 flex justify-end space-x-3">
                        <slot name="footer">
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script setup>
import CloseIcon from "../../../public/assets/icons/board/CloseIcon.vue";
defineProps({
    modelValue: {
        type: Boolean,
        required: true
    }
});

const emit = defineEmits(['update:modelValue', 'confirm']);

const close = () => {
  emit('update:modelValue', false);
};
</script>