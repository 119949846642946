<template>
    <div class="rounded-[16px] bg-ivory px-[40px] py-[24px] text-center object-center">
        <img class="w-[86px] h-[86px] mx-auto mb-[5px]" src="../../../public/assets/images/seed.png" alt="placeholder">
        <p class="text-[#63615E] w-[195px] text-[14px] mx-auto">Nothing! How about sowing some “seeds”?</p>

    </div>
</template>

<style lang="scss" scoped>
* {
  font-family: "inter";
  font-style: normal;
}
</style>