<template>
  <master-layout>
    <div v-if="isLoading" class="w-screen h-screen flex items-center justify-center">
        <Loading />
    </div>
    <div
      v-else
      class="h-[calc(var(--app-height)-(88px))] lg:flex-1 pt-[24px] pb-[123px] md:pb-[138px] md:pt-[32px] md:px-[56px] px-[24px]"
    >
      <header class="z-10 mb-[24px]">
        <router-link
          class="flex items-center rounded-full"
          to="/"
        >
          <img
            class="block"
            src="../../../public/assets/icons/chevron-left-white.svg"
            alt=""
          />
        </router-link>
      </header>
      <section class="pb-6">
        <div class="pills-section flex items-start flex-wrap">
        <div v-for="weekend in weekends">
          <router-link class="pills" :to="`/service-replay/`+ weekend.hogc_one_replays[0].id">
            {{ weekend.WeekendDates }}
          </router-link>
        </div>  
      </div>
      <!-- Leader service replay passcode reveal -->
      <div v-if="isLeader" class="bg-[#2B2926] text-[#ffffff] px-[16px] py-[16px] my-[8px] rounded-[16px]">
        <button class="flex items-center show-menu-button" @click="updateShowPasscode">
          <div
            class="text-[16px]"
          >
            Guest accessing Service Replay?
          </div>
          <img
            v-if="!showPasscode"
            class="ml-[4px] rotate-180"
            src="../../../public/assets/icons/chevron-down-white.svg"
            alt=""
          />
          <img v-else class="ml-[4px]" src="../../../public/assets/icons/chevron-down-white.svg" alt="" />
        </button>
        <div v-if="showPasscode" class="text-[14px]">
          <p>Access code</p>
          <input
            class="bg-[#ffffff] text-[#000000]"
            v-model="passcode"
            type="text"
            disabled
          />
        </div>
      </div> 
        <div class="w-full max-w-[936px]">
          <div v-if="currentReplay.isMux" class="w-full">
            <mux-player
              :playback-id="currentReplay.VideoUrl"
              :metadata-video-title="currentReplay.Title"
              :metadata-viewer-user-id="memberId"
              primary-color="#ffffff"
              secondary-color="#000000"
            ></mux-player>
          </div>
          <div v-else class="relative pt-[56.25%]">
            <iframe
              class="absolute top-0 left-0 w-full h-full"
              :src="currentReplay.VideoUrl"
              :title="currentReplay.VideoTitle"
              allow="autoplay; fullscreen; picture-in-picture"
            >
            </iframe>
          </div>
        </div>

        <p class="mt-[8px]"> {{ currentReplay.Title }} - {{ currentReplay.Duration }}</p>
        <h1
          class="text-[24px] leading-[32px] md:text-[32px] md:leading-[40px] text-[#0B0C0F] font-bold"
        >
          {{ currentReplay.VideoTitle }}
        </h1>
        <!-- <p class="mt-[8px] md:mt-[16px]">{{ current.description }}</p> -->
        <div v-if="currentWeekend.hogc_one_replays" v-for="replay in currentWeekend.hogc_one_replays" class="mt-[24px]">
          <!-- Thumbnails -->
          <router-link
            class="resource-card z-0 cursor-pointer overflow-hidden flex"
            :to="`/service-replay/` + replay.id"
          >
            <div class="relative w-[140px] h-[80px] md:w-[262px] md:h-[148px] rounded-[16px]">
              <img
                  class="w-full h-full"
                  :src="replay.Thumbnail?.url"
                  alt=""
              />
              <img
                class="absolute top-[50%] left-[50%] tranform translate-x-[-50%] translate-y-[-50%]"
                src="../../../public/assets/icons/video-play-button.svg"
                alt=""
              />
            </div>
            <div
              class="z-100 px-[8px] py-[4px]"
            >
              <p class="text-[12px] leading-[20px] text-[#808080]">
                {{ replay.Title }}
              </p>
              <p class="text-[16px] leading-[20px] text-[#000]">
                {{ replay.VideoTitle }}
              </p>
              <p class="text-[12px] leading-[20px] text-[#808080] pt-[12px]">
                {{ replay.Duration }}
              </p>
            </div>
          </router-link>
        </div>
      </section>
    </div>
  </master-layout>
</template>

<script>
import {mapState} from "vuex";
import Loading from "@/components/common/LoadingState.vue";
import MuxPlayer from '@mux/mux-player'

export default {
  head() {
    return {
      script: [
        {
          src: "https://cdn.jsdelivr.net/npm/@mux/mux-player",
        },
      ],
    };
  },
  data() {
      return {
        currentReplay: {},
        currentWeekend: {},
        showPasscode: false,
        isLeader: false
      };
    },
  components: {
    Loading,
  },
  computed: {
    ...mapState("serviceReplay", {
      isLoading: (state) => state.isLoading,
      weekends: (state) => state.weekends,
      hasEnteredPassword: (state) => state.hasEnteredPassword,
      passcode: (state) => state.passcode,
    }),
    ...mapState("profile", {
      pastoralStatus: (state) => state.memberCgZones[0].role.name,
      memberId: (state) => state.id
    })
  },
  methods: {
    back () {
      this.$router.go(-1)
    },
    checkPastoralStatus () {
      if (this.pastoralStatus == 'V' || this.pastoralStatus == 'N' ) {
        //Redirect out of page
        back()
      }
      else if (this.pastoralStatus == 'G' && !this.hasEnteredPassword) {
        //Redirect to passcode page if password not entered yet
        console.log("Role: ", this.pastoralStatus, "hasEnteredPassword", this.hasEnteredPassword)
        this.$router.push('/service-replay/passcode')      
      }
    },
    checkLeadershipStatus () {
      //Whether to enable passcode to be revealed if PTL and above
      if (this.pastoralStatus == 'PTL' ) {
        //Show passcode component
        this.isLeader = true
      }
      else {
        this.isLeader = false
      }
    },
    updateShowPasscode () {
      this.showPasscode = !this.showPasscode
    },
  },
  async ionViewWillEnter() {
    // await this.$store.dispatch("serviceReplay/fetchServiceReplayById", {
    //   serviceReplayId: this.$route.params.id,
    // });
    await this.$store.dispatch("profile/fetchProfile"),
    await this.$store.dispatch("serviceReplay/fetchPasscode");

    await this.$store.dispatch("serviceReplay/fetchWeekends"),

    //Check pastoral status
    this.checkPastoralStatus ()
    this.checkLeadershipStatus ()

    this.weekends.forEach(weekend => {
      weekend.hogc_one_replays.forEach(replay => {
        if (replay.id == this.$route.params.id) {
          this.currentReplay = replay
          let curWeekend = JSON.parse(JSON.stringify(weekend))
          curWeekend.hogc_one_replays = curWeekend.hogc_one_replays.filter((curReplay) => curReplay.id != this.$route.params.id)
          this.currentWeekend = curWeekend
        }
      })
    });
  },
};
</script>

<style scoped>
.pills {
  border-radius: 40px;
  border: 2px solid #0b0c0f;
  color: #0b0c0f;
  /* Body 1 */
  font-family: Proxima Nova;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  display: flex;
  padding: 5px 20px;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  margin-bottom: 16px;
}

.pills-section {
  /* gap: 16px; */
  flex-wrap: wrap;
}


</style>