import dayjs from "dayjs";
import markerSDK from "@marker.io/browser";
import { formatDate } from "@/utils/formatDateToTime";
import { isPlatform } from "@ionic/vue";
import axios from "axios";
import OneSignal from "onesignal-cordova-plugin";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { startSmartlook } from "../../utils/smartlook";

const INITIAL_STATE = {
  id: undefined,
  personalId: undefined,
  children: [],
  dob: undefined,
  dob_ddmm: undefined,
  email: { email: undefined },
  emailVerified: undefined,
  firstGenChristian: undefined,
  gender: undefined,
  haveChildren: undefined,
  lifephases: [],
  maritalStatus: undefined,
  memberCgZones: [],
  name: "",
  nationalityId: undefined,
  numberOfChildren: undefined,
  phones: [],
  preHogcId: undefined,
  residentialId: undefined,
  surname: "",
  uin: null,
  loading: false,
  // pastoralAbbrevMapping: {},  //Don't need
  ministryStatus: "",
  hasSeenLdrsCal: false,
  hasSeenUpdates: false,
  igHandle: "",
  isLeader: false,
  showingProfileModal: false,
};

const state = () => INITIAL_STATE;

const mutations = {
  setProfileState(state, newProfile) {
    for (const key in newProfile) state[key] = newProfile[key];
  },
  setLoading(state, isLoading) {
    state.loading = isLoading;
  },
  //Don't need, redundant
  // setPastoralAbbrevMapping(state, { value }) {
  //   state.pastoralAbbrevMapping = value;
  // },
  setMinistryStatus(state, ministryStatus) {
    state.ministryStatus = ministryStatus;
  },
  setHasSeenLdrsCal(state, hasSeenLdrsCal) {
    state.hasSeenLdrsCal = hasSeenLdrsCal;
  },
  setHasSeenUpdates(state, hasSeenUpdates) {
    state.hasSeenUpdates = hasSeenUpdates;
  },
  setIsLeader(state, isLeader) {
    state.isLeader = isLeader;
  },
  setShowingProfileModal(state, showingProfileModal) {
    state.showingProfileModal = showingProfileModal;
  },
  setDob(state, dob) {
    state.dob = dob;
  },
  setUin(state, uin) {
    state.uin = uin;
  },
};

const actions = {
  async fetchProfile({ dispatch, state }) {
    // Will not call backend api if profile already set
    if (state.name) {
      if (process.env.VUE_APP_NODE_ENV === "staging") {
        markerSDK.loadWidget({
          destination: process.env.VUE_APP_MARKERIO_DESTINATION, // <- Your unique destination ID
          reporter: {
            email: state.email.email,
            fullName: state.name,
          },
        });
      }
      return;
    }
    await dispatch("forceFetchProfile");
    // window.$crisp.push(['set', 'user:email', [state.email.email]])
    // window.$crisp.push(['set', 'user:nickname', [state.name]])
    const filteredMemberCgZone = state.memberCgZones.filter((c) => c.primaryCg);
    // if (filteredMemberCgZone.length) {
    //   window.$crisp.push(['set', 'session:data', ['cg', filteredMemberCgZone[0].cg.cgName]])
    //   window.$crisp.push(['set', 'session:data', ['role', filteredMemberCgZone[0].roleId]])
    // }
  },


  /** Might not need to use it, 
   * Do we really need to show a list of all the member roless?
   * We have member's role id and role name in the profile api
  **/
 
  // async fetchPastoralAbbrevMapping({ state, commit, dispatch, rootState }) {
  //   if (Object.keys(state.pastoralAbbrevMapping).length) return;

  //   try {
  //     commit("setLoading", true);
  //     const accessToken = rootState.user.token;
  //     const { data } = await axios.get(
  //       process.env.VUE_APP_API_URL + "/profile/pastoral-abbrev-mapping",
  //       {
  //         headers: {
  //           Authorization: `Bearer ${accessToken}`,
  //         },
  //       }
  //     );
  //     commit("setPastoralAbbrevMapping", { value: data });
  //     commit("setLoading", false);
  //   } catch (e) {
  //     dispatch("toast/error", "Failed to fetch roleid abbrev mapping", {
  //       root: true,
  //     });
  //   }
  // },
  async forceFetchProfile({ commit, dispatch, rootState }) {
    commit("setLoading", true);
    console.log("in forceFetchProfile");

    try {
      const isNative = isPlatform("ios") || isPlatform("android");
      const isNativeBrowser = isPlatform("mobileweb");
      commit("setLoading", true);
      const accessToken = rootState.user.token;
      const { status, data } = await axios.get(
        process.env.VUE_APP_API_URL + "/profile",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (status !== 200) throw new Error("Failed in setting profile");

      // strip time and timezone context for date of birth so that it
      // always display the same date no matter the timezone
      if (data.dob) {
        const dob = new Date(data.dob);
        const date = new Date(
          dob.getFullYear(),
          dob.getUTCMonth(),
          dob.getUTCDate()
        );

        data.dob = formatDate(date, false);
      }

      commit("setProfileState", data);
      commit("setLoading", false);

      // window.$crisp.push(['set', 'user:email', [data.email.email]])
      // window.$crisp.push(['set', 'user:nickname', [data.name]])
      const filteredMemberCgZone = data.memberCgZones.filter(
        (c) => c.primaryCg
      );
      // if (filteredMemberCgZone.length) {
      //   window.$crisp.push(['set', 'session:data', ['cg', filteredMemberCgZone[0].cg.cgName]])
      //   window.$crisp.push(['set', 'session:data', ['role', filteredMemberCgZone[0].roleId]])
      // }

      let memberCGfetched = null;
      if (filteredMemberCgZone.length) {
        memberCGfetched = filteredMemberCgZone[0].cg.cgName;
      }

      let memberRoleFetched = null;
      if (filteredMemberCgZone.length) {
        memberRoleFetched = filteredMemberCgZone[0].roleId;
      }

      let memberRoleNameFetched = null;
      if (filteredMemberCgZone.length) {
        memberRoleNameFetched = filteredMemberCgZone[0].role.name;
      }

      let memberAgeFetched = null
      memberAgeFetched = new Date().getFullYear() - new Date(data.dob).getFullYear();
  
      const userProps = {
        memberEmail: data.email.email,
        memberName: data.name,
        memberCG: memberCGfetched,
        memberRole: memberRoleFetched,
      };
      if (isNative && !isNativeBrowser) {
        OneSignal.login("HOGC-" + data.id);
        // OneSignal.setEmail(data.email);
        OneSignal.User.addTags({ ...userProps });

        /**
         * Platform: Web/Android/iOS
         * Sets a user property to a given value.
         * @param options - property name and value to set
         * @returns void
         * https://firebase.google.com/docs/analytics/user-properties
         */
        // FirebaseAnalytics.setUserId({
        //   userId: data.id,
        // });
        FirebaseAnalytics.setUserProperty({
          name: "user_identifier",
          value: data.name.toString(),
        });
        FirebaseAnalytics.setUserProperty({
          name: "id",
          value: data.personalId.toString(),
        });
        
        FirebaseAnalytics.setUserProperty({
          name: "age",
          value: memberAgeFetched.toString(),
        });
        FirebaseAnalytics.setUserProperty({
          name: "pastoral_status",
          value: memberRoleNameFetched.toString(),
        });
        FirebaseAnalytics.setUserProperty({
          name: "cg",
          value: memberCGfetched.toString(),
        });

        console.log("Smart Look - Start");
        startSmartlook(data.email.email, data.name, data.id);
        console.log("Smart Look - End");
      }
      // window.dataLayer.push({
      //   event: 'login',
      //   name: data.name,
      //   pastoralStatus: filteredMemberCgZone[0].role.name,
      //   cg: data.memberCgZones[0].cg.cgName,
      //   zone: data.memberCgZones[0].cg.zone.zoneName,
      // })
    } catch (e) {
      dispatch("toast/error", "Failed to load profile.", { root: true });
    } finally {
      commit("setLoading", false);
    }
  },
  async updateProfile({ commit, dispatch, rootState }, updatedProfile) {
    commit("setLoading", true);
    try {
      const accessToken = rootState.user.token;
      await axios.request(process.env.VUE_APP_API_URL + "/profile/update", {
        method: "post",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: updatedProfile,
      });
      dispatch("toast/show", "Successfully updated user profile detail", {
        root: true,
      });
      dispatch("forceFetchProfile");
    } catch (error) {
      dispatch("toast/error", "Error updating profile.", { root: true });
    } finally {
      commit("setLoading", false);
    }
  },

  async fetchMinistryStatus({ state, commit, dispatch, rootState }) {
    if (state.ministryStatus) return;

    try {
      const accessToken = rootState.user.token;
      const { data } = await axios.get(
        process.env.VUE_APP_API_URL + "/profile/ministry-status",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      commit("setMinistryStatus", data);
    } catch (error) {
      dispatch("toast/error", "Error getting ministry status", { root: true });
    }
  },

  async updateHasSeenLdrsCal({ commit, rootState }) {
    commit("setHasSeenLdrsCal", true);
    const accessToken = rootState.user.token;
    await axios.request(process.env.VUE_APP_API_URL + "/profile/update", {
      method: "post",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: { hasSeenLdrsCal: true },
    });
  },

  async updateHasSeenUpdates({ commit, rootState }) {
    commit("setHasSeenUpdates", true);
    const accessToken = rootState.user.token;
    await axios.request(process.env.VUE_APP_API_URL + "/profile/update", {
      method: "post",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: { hasSeenUpdates: true },
    });
  },

  updateIsLeader({ commit }, isLeader) {
    commit("setIsLeader", isLeader);
  },
};

const getters = {
  cgId(state) {
    const filteredMemberCgZone = state.memberCgZones.filter((c) => c.primaryCg);
    if (filteredMemberCgZone.length) return filteredMemberCgZone[0].cgId_zoneId;
    return null;
  },
  cgName(state) {
    const filteredMemberCgZone = state.memberCgZones.filter((c) => c.primaryCg);
    if (filteredMemberCgZone.length) return filteredMemberCgZone[0].cg.cgName;
    return null;
  },
  zoneName(state) {
    const filteredMemberCgZone = state.memberCgZones.filter((c) => c.primaryCg);
    if (filteredMemberCgZone.length)
      return filteredMemberCgZone[0].cg.zone.zoneName;
    return null;
  },
  age(state) {
    return new Date().getFullYear() - new Date(state.dob).getFullYear();
  },
  fullname(state) {
    return `${state.surname} ${state.name}`;
  },
  email(state) {
    return state.email.email;
  },
  encodedEmail(state) {
    return state.email.email;
  },
  localMobileNumber(state) {
    const filteredPhones = state.phones.filter(
      (p) => p.phoneTypeId === 1 && p.primaryPhone === 1
    );
    if (filteredPhones.length) return filteredPhones[0].phone;
    return "";
  },
  countryCode(state) {
    const filteredPhones = state.phones.filter(
      (p) => p.phoneTypeId === 1 && p.primaryPhone === 1
    );
    if (filteredPhones.length) return filteredPhones[0].countryCode;
    return "";
  },
  pastoralRoleId(state) {
    if (state.memberCgZones.length) {
      return state.memberCgZones[0]?.roleId;
    }
  },
  genderLongAbbr(state) {
    if (state.gender === "M") return "Male";
    if (state.gender === "F") return "Female";
    return null;
  },
  pastoralStatus(state) {
    return state.pastoralAbbrevMapping[state.memberCgZones[0]?.roleId];
  },
  payload(state) {
    if (!state.name && !state.surname) {
      return {
        fullname: undefined,
        gender: undefined,
        dob: undefined,
        dob_ddmm: undefined,
        nationalityId: undefined,
        residentialId: undefined,
        maritalStatus: undefined,
        numberOfChildren: undefined,
        uin: undefined,
        haveChildren: undefined,
        preHogcId: undefined,
        firstGenChristian: undefined,
        childrenBirthYears: [],
        phaseId: undefined,
        levelRankId: undefined,
        educationalPhaseYear: undefined,
        school: undefined,
        courseIndustry: undefined,
        partTimeStudy: undefined,
      };
    }
    let dob = dayjs(state.dob).format("YYYY-MM-DD");
    if (dob === "Invalid Date") dob = undefined; // to maintain falsy value if invalid date

    const childrenBirthYears = [];
    if (state.haveChildren && state.numberOfChildren && state.children.length) {
      for (let i = 0; i < state.numberOfChildren; i++) {
        childrenBirthYears.push(state.children[0][`childBirthYear${i + 1}`]);
      }
    }

    for (let i = 0; i < 10 - state.numberOfChildren; i++) {
      childrenBirthYears.push(undefined);
    }

    return {
      fullname: `${state.surname} ${state.name}`,
      gender: state.gender,
      dob,
      dob_ddmm: state.dob_ddmm,
      nationalityId: state.nationalityId ?? 1,
      residentialId: state.residentialId,
      maritalStatus: state.maritalStatus,
      numberOfChildren: state.numberOfChildren,
      uin: state.uin,
      haveChildren: state.haveChildren,
      preHogcId: state.preHogcId,
      firstGenChristian: state.firstGenChristian,
      childrenBirthYears,
      phaseId:
        state.lifephases && state.lifephases.length
          ? state.lifephases[0].phaseId
          : undefined,
      levelRankId:
        state.lifephases && state.lifephases.length
          ? state.lifephases[0].levelRankId
          : undefined,
      educationalPhaseYear:
        state.lifephases && state.lifephases.length
          ? state.lifephases[0].educationalPhaseYear
          : undefined,
      school:
        state.lifephases && state.lifephases.length
          ? state.lifephases[0].school
          : undefined,
      courseIndustry:
        state.lifephases && state.lifephases.length
          ? state.lifephases[0].courseIndustry
          : undefined,
      partTimeStudy:
        state.lifephases && state.lifephases.length
          ? state.lifephases[0].partTimeStudy
          : undefined,
      igHandle: state.igHandle,
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
