<template>
    <div class="max-w-full rounded-[16px] p-[12px] border-[#C3C2C1] border-[1px] border-solid" :class="hasPast ? 'bg-[#ECEBEB]' : 'bg-ivory'">
        <div class="flex items-start gap-6">
            <div class="flex flex-col items-center w-[70px] my-auto">
                <EventDateInfo :has-past="hasPast" :start-date="startDate" :end-date="endDate" />
            </div>

            <div class="flex-1 border-l border-[#C3C2C1] pl-[16px]">
                <div class="text-[16px] font-extrabold leading-[24px] mb-1" :class="hasPast ? pastClass : ''">{{ title }}</div>

                <EventWhenWhere :has-past="hasPast" :event-time="eventTime" :event-location="eventLocation" />

                <div class="flex items-center justify-between">
                    <EventLabels :has-past="hasPast" :tags="tags" />
                </div>
                <button
                    class="flex items-center text-gray-700 font-bold text-[#21201E] text-[14px] leading-[20px] mt-2 ml-auto"
                    :class="hasPast ? pastClass : ''">
                    <a :href="link" target="_blank"><span class="pr-[8px]">Learn More</span></a>
                    <img src="../../../../public/assets/icons/milestones/chevron-right-white.svg"
                        class="w-[16px] h-[16px] rounded-full bg-black" />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import EventDateInfo from '@/components/Calendar/Events/EventDateInfo.vue'
import EventWhenWhere from '@/components/Calendar/Events/EventWhenWhere.vue'
import EventLabels from '@/components/Calendar/Events/EventLabels.vue'
import dayjs from 'dayjs';
export default {
    components: {
        EventDateInfo,
        EventWhenWhere,
        EventLabels,
    },
    data() {
        return {
            pastClass: 'text-[#7C8A9C]',
        }
    },
    props: {
        title: {
            type: String,
            required: true,
            default: ''
        },
        description: {
            type: String,
            default: null,
        },
        eventTime: {
            type: Array,
            default: [''],
        },
        eventLocation: {
            type: String,
            default: '',
        },
        link: {
            type: String,
            default: '',
        },
        startDate: {
            type: String,
            required: true,
            default: ''
        },
        endDate: {
            type: String,
            required: true,
            default: ''
        },
        tags: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        hasPast () {
            return dayjs().isAfter(this.endDate, 'day')
        },
    }
}
</script>