<template>
  <div class="flex flex-col items-center">
    <div
      v-if="!isMainPage"
      class="hidden sm:flex cursor-pointer mb-[16px] lg:max-w-[1056px] md:max-w-[700px] w-full pl-3"
      @click="redirectMainBoard"
    >
      <img
        src="../../../../public/assets/icons/board/back-to-board.svg"
        class="h-[24px] w-[24px] mr-[4px]"
        alt="back logo"
      />
      <p class="text-[16px] leading-[24px]">Back</p>
    </div>
    <div
      v-if="isMainPage"
      class="mt-[13px] mb-[13px] lg:max-w-[1056px] md:max-w-[700px] w-full pl-3"
    >
      <div
        class="flex flex-row items-center cursor-pointer"
        @click="redirectToSelectYear()"
      >
        <img
          src="../../../../public/assets/icons/board/back-to-board.svg"
          class="h-[24px] w-[24px] md:h-[32px] md:w-[32px]"
          alt="back logo"
        />
        <p
          class="ml-[6px] font-bold text-[24px] leading-[24px] md:text-[32px] md:leading-[32px]"
        >
          {{ currBoard.year }}
        </p>
      </div>
    </div>
    <VueFlexWaterfall
      align-content="center"
      col="4"
      col-spacing="8"
      :break-at="{ 1024: 2, 768: 1, 640: 1, 375: 1 }"
    >
      <div
        v-for="(post, index) in filteredPosts"
        :key="index"
        class="bg-[#F8F9F9]"
      >
        <MessageCard
          class="mb-[12px] md:mb-[24px] md:max-w-[328px] md:w-[328px] bg-[#ffffff]"
          :member="post.member"
          :message="post.message"
          :attachment-url="post.attachmentUrl"
          :is-me="post.isMe"
          :post-id="post.id"
          :board-id="post.boardId"
          :is-pinned="post.isPinned"
          :is-hidden="post.isHidden"
          :is-anon="post.isAnon"
          :is-favourite="post.isFavourite"
          :is-read="post.isRead"
          :year-created-at="post.yearOfBoard"
          :tags="post.tags"
          @open-options="updateShowOptionsModal(true)"
        />
      </div>
      <div v-if="filteredPosts.length < 3" class="flex flex-row">
        <div v-for="(i, index) in range(3 - filteredPosts.length)" :key="index">
          <div
            :id="i"
            class="mb-[12px] max-w-[100vw] md:mb-[24px] md:max-w-[328px] md:w-[328px] bg-transparent"
          />
        </div>
      </div>
    </VueFlexWaterfall>
    <div ref="infiniteLoading" class="w-full" id="infinite-loading">
      <div
        v-if="filteredPosts.length > 0"
        class="h-[40px] mb-[8px] lg:mb-[0px]"
      >
        Hope reading the messages above <br />
        have encouraged you!
      </div>
      <div v-if="filteredPosts.length === 0" class="h-[40px]">
        <EmptyState
          :image="emptyStateContent?.image"
          :title="emptyStateContent.title"
          :body="emptyStateContent.body"
        />
      </div>
    </div>
    <Transition name="fade">
      <MobileModal v-if="showMobileOptionsModal">
        <Transition name="slide-options" appear>
          <MessageOptions
            @close="updateShowOptionsModal(false)"
            @delete-message="showDeletePostToast"
            @pin-message="showPinPostToast"
            @unpin-message="showUnpinPostToast"
            @hide-message="showHidePostToast"
            @unhide-message="showUnhidePostToast"
            @handle-tags-updated="handleTagsUpdated"
          />
        </Transition>
      </MobileModal>
    </Transition>

    <Transition :name="isMobile">
      <MobileModal v-if="showFilterModal">
        <FilterOptions />
      </MobileModal>
    </Transition>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import InfiniteLoading from "v3-infinite-loading";
import MobileModal from "@/components/common/MobileModal";
import MessageCard from "@/components/Board/Card/MessageCard.vue";
import MessageOptions from "@/components/Board/Card/MessageOptions";
import FilterOptions from "@/components/Board/Filter/FilterOptions";
import EmptyState from "@/components/Board/EmptyState";
import axios from "axios";
import { VueFlexWaterfall } from "vue-flex-waterfall";

export default {
  components: {
    InfiniteLoading,
    MobileModal,
    MessageCard,
    MessageOptions,
    FilterOptions,
    EmptyState,
    VueFlexWaterfall,
  },
  props: {
    isMyMessages: {
      type: Boolean,
      required: false,
      default: false,
    },
    isFavouriteMessages: {
      type: Boolean,
      required: false,
      default: false,
    },
    isPinnedMessages: {
      type: Boolean,
      required: false,
      default: false,
    },
    isHiddenMessages: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      zone: "",
      posts: [],
      filteredPosts: [],
      page: 1,
      cursor: null,
      perPage: 20,
      moreMessagePerPage: 300,
      taggedPost: false,
    };
  },
  computed: {
    ...mapState("board", [
      "currBoard",
      "currPost",
      "isCurrBoardAdmin",
      "showOptionsModal",
      "showFilterModal",
      "readFilterValue",
      "myZoneFilterValue",
      "zoneFilters",
      "tagFilters",
    ]),
    ...mapState("user", ["token"]),
    ...mapGetters({
      zoneName: "profile/zoneName",
    }),
    isMobile() {
      return window.innerWidth <= 500 ? "slide" : "fade";
    },
    isMainPage() {
      return this.$route.path === "/birthdayboard";
    },
    // showMobileOptionsModal () {
    //   // Check the viewport width and return the appropriate boolean value
    //   return window.innerWidth < 500 && this.showOptionsModal
    // },
    showMobileOptionsModal() {
      // Check the viewport width and return the appropriate boolean value
      return this.showOptionsModal;
    },

    getBoardPostsPath() {
      if (this.isMyMessages) {
        return `/board/posts/my-messages?cursor=${this.cursor}&per_page=${this.perPage}`;
      } else if (this.isFavouriteMessages) {
        return `/board/${this.currBoard.id}/posts/favourite-messages?cursor=${this.cursor}&per_page=${this.perPage}&year=${this.currBoard.year}`;
      } else if (this.isHiddenMessages) {
        return `/board/${this.currBoard.id}/posts/hidden?cursor=${this.cursor}&per_page=${this.perPage}&year=${this.currBoard.year}`;
      } else if (this.isPinnedMessages) {
        return `/board/${this.currBoard.id}/posts/pinned?cursor=${this.cursor}&per_page=${this.perPage}&year=${this.currBoard.year}`;
      } else if (this.isCurrBoardAdmin) {
        return `/board/${this.currBoard.id}/posts/admin?cursor=${this.cursor}&per_page=${this.perPage}&year=${this.currBoard.year}`;
      } else if (
        this.readFilterValue !== "all" ||
        this.myZoneFilterValue !== "all" ||
        this.zoneFilters.length > 0
      ) {
        return `/board/${this.currBoard.id}/posts?cursor=${this.cursor}&per_page=${this.moreMessagePerPage}&year=${this.currBoard.year}`;
      } else {
        return `/board/${this.currBoard.id}/posts?cursor=${this.cursor}&per_page=${this.perPage}&year=${this.currBoard.year}`;
      }
    },
    emptyStateContent() {
      if (
        this.$route.path === "/birthdayboard" &&
        this.myZoneFilterValue !== "myzone" &&
        this.zoneFilters.length === 0
      ) {
        return {
          title: "Be the first to share your birthday message for SP!",
          body: "Click ‘+’ to add your message! You're moments away from sending some encouragement and appreciation 🥹🎉",
        };
      } else if (
        this.$route.path === "/birthdayboard" &&
        this.myZoneFilterValue === "myzone"
      ) {
        return {
          title: "Welcome to your zone's very own birthday board for SP!",
          body: "Click ‘+’ and be the first in your zone to share your birthday message for SP! You're moments away from sending some encouragement and appreciation 🥹🎉",
        };
      } else if (
        this.$route.path === "/birthdayboard" &&
        this.zoneFilters.length > 0
      ) {
        return {
          title: "View More Messages",
          body: "Check out the churchwide board or other zone boards for more messages!",
        };
      } else if (this.$route.path === "/birthdayboard/my-messages") {
        return {
          image: "message-plane",
          title: "Send Your Birthday Greetings",
          body: "Click ‘+’ to add your message! You're moments away from sending some encouragement and appreciation 🥹🎉",
        };
      } else if (this.$route.path === "/birthdayboard/favourite-messages") {
        return {
          image: "favourite-message",
          title: "Save Your Favorite Messages",
        };
      } else if (this.$route.path === "/birthdayboard/pinned-messages") {
        return {
          image: "pinned-message",
          title: "No Pinned Messages Yet",
        };
      } else if (this.$route.path === "/birthdayboard/hidden-messages") {
        return {
          image: "hidden-message",
          title: "No Hidden Messages Yet",
        };
      } else {
        return {
          title: "",
          body: "",
        };
      }
    },
  },
  watch: {
    currBoard() {
      this.resetPosts();
    },
    readFilterValue() {
      this.resetPosts();
    },
    myZoneFilterValue() {
      this.resetPosts();
    },
    zoneFilters() {
      this.resetPosts();
    },
    tagFilters() {
      this.resetPosts();
    },
    posts() {
      this.filterPosts();
    },
  },
  async created() {
    await this.$store.dispatch("profile/fetchProfile");
    this.zone = this.zoneName;
  },
  mounted() {
    this.loadMorePosts();
  },
  methods: {
    resetPosts() {
      // if (this.$refs.infiniteLoading) {
      //   this.$refs.infiniteLoading.stateChanger.reset()
      // }
      this.posts = [];
      this.page = 1;
      this.cursor = null;
    },
    loadMorePosts() {
      setTimeout(async () => {
        const result = await axios.get(
          process.env.VUE_APP_API_URL + this.getBoardPostsPath,
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );
        const { cursor, posts: additionalItems } = result.data;
        if (additionalItems?.length) {
          if (this.isHiddenMessages) {
            this.posts.push(...additionalItems);
          } else {
            this.posts.push(
              ...additionalItems.filter((post) => !post.isHidden)
            );
          }
          this.filteredPosts = this.posts;
          this.cursor = cursor;
        }
        this.$emit("set-debounce", false);
      }, 500);
    },
    updateShowOptionsModal(showOptionsModal) {
      this.$store.dispatch("board/updateShowOptionsModal", showOptionsModal);
    },
    showDeletePostToast() {
      this.$store.dispatch("board/updateIsBoardToastOpen", true);
      this.$store.dispatch("board/updateBoardToastMessage", "Post deleted!");
      this.posts = this.posts.filter((post) => post.id !== this.currPost.id);
      window.scrollTo(0, 0);
      this.$store.dispatch("board/updateCurrPost", null);
    },
    showPinPostToast() {
      this.$store.dispatch("board/updateIsBoardToastOpen", true);
      this.$store.dispatch("board/updateBoardToastMessage", "Post pinned!");
      this.posts = this.posts.filter((post) => post.id !== this.currPost.id);
      const pinnedPost = Object.assign({}, this.currPost);
      pinnedPost.isPinned = true;
      this.posts.unshift(pinnedPost);
      this.$store.dispatch("board/updateCurrPost", null);
    },
    showUnpinPostToast() {
      this.$store.dispatch("board/updateIsBoardToastOpen", true);
      this.$store.dispatch("board/updateBoardToastMessage", "Post unpinned!");
      this.posts = this.posts.filter((post) => post.id !== this.currPost.id);
      const unpinnedPost = Object.assign({}, this.currPost);
      unpinnedPost.isPinned = false;
      this.insertPost(unpinnedPost);
      this.$store.dispatch("board/updateCurrPost", null);
    },
    showHidePostToast() {
      this.posts = this.posts.map((post) => {
        if (post.id === this.currPost.id) {
          post.isHidden = true;
        }
        return post;
      });
      this.$store.dispatch("board/updateIsBoardToastOpen", true);
      this.$store.dispatch("board/updateBoardToastMessage", "Post hidden!");
      this.$store.dispatch("board/updateCurrPost", null);
    },
    showUnhidePostToast() {
      this.posts = this.posts.map((post) => {
        if (post.id === this.currPost.id) {
          post.isHidden = false;
        }
        return post;
      });
      this.$store.dispatch("board/updateIsBoardToastOpen", true);
      this.$store.dispatch("board/updateBoardToastMessage", "Post unhidden!");
      this.$store.dispatch("board/updateCurrPost", null);
    },
    insertPost(newPost) {
      // insert back in order
      const pinnedPosts = this.posts.filter((post) => post.isPinned);
      const unpinnedPosts = this.posts.filter((post) => !post.isPinned);
      // find position to insert in unpinned posts
      const index = unpinnedPosts.findIndex((post) => post.id > newPost.id);
      unpinnedPosts.splice(index, 0, newPost);
      this.posts = [...pinnedPosts, ...unpinnedPosts];
    },
    filterPosts() {
      // Filter posts based on readFilterValue first
      let intermediatePosts = [];
      if (
        this.readFilterValue === "all" ||
        this.$route.path !== "/birthdayboard"
      ) {
        intermediatePosts = [...this.posts];
      } else if (this.readFilterValue === "read") {
        intermediatePosts = this.posts.filter((post) => post.isRead === true);
      } else if (this.readFilterValue === "unread") {
        intermediatePosts = this.posts.filter((post) => post.isRead === false);
      }

      let intermediatePosts2 = [];
      // Filter on MyZoneFilterValue
      if (this.myZoneFilterValue === "myzone") {
        intermediatePosts2 = intermediatePosts.filter((post) => {
          if (!post.isAnon) {
            return post.member.zone.toUpperCase() === this.zone.toUpperCase();
          } else {
            return "";
          }
        });
      } else if (
        this.zoneFilters.length > 0 &&
        this.myZoneFilterValue !== "myzone"
      ) {
        intermediatePosts2 = intermediatePosts.filter((post) => {
          if (!post.isAnon) {
            return this.zoneFilters.includes(post.member.zone.toUpperCase());
          } else {
            return "";
          }
        });
      } else {
        intermediatePosts2 = [...intermediatePosts];
      }

      let intermediatePosts3 = [];
      // Filter on TagFilterValue
      if (this.isCurrBoardAdmin && this.tagFilters.length > 0) {
        intermediatePosts3 = intermediatePosts2.filter((post) => {
          return this.tagFilters.some((tag) => post.tags.includes(tag));
        });
      } else {
        intermediatePosts3 = [...intermediatePosts2];
      }
      this.filteredPosts = intermediatePosts3;
    },
    range(count) {
      return Array(count)
        .fill()
        .map((_, index) => index);
    },
    redirectMainBoard() {
      this.$router.push("/birthdayboard");
    },
    redirectToSelectYear() {
      this.$store.dispatch("board/updateIsYearSelected", false);
      this.$store.dispatch("board/resetFilters");
    },
    handleTagsUpdated({ postId, tags }) {
      if (!postId) return;
      // Find the post and update its tags
      const postIndex = this.posts.findIndex((post) => post.id === postId);
      if (postIndex !== -1) {
        this.$set(this.posts[postIndex], "tags", tags);
      }
      // Optionally, re-filter posts if necessary
      this.filterPosts();
    },
  },
};
</script>

<style scoped>
.slide-enter,
.slide-leave-to {
  transform: translateY(100%);
}
.slide-enter-active,
.slide-leave-active {
  transition: 0.4s ease-in-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: 0.2s ease-in-out;
}

.slide-options-enter,
.slide-options-leave-to {
  transform: translateY(100%);
}

.slide-options-enter-active,
.slide-options-leave-active {
  transition: all 0.4s ease-in 0.15s;
}
</style>
