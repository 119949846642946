<template>
  <div class="">
    <h1 class="font-[Inter] text-[24px] leading-[40px] text-white font-black">Downloadables</h1>
    <BaseSwiper
      :id="baseSwiperId"
      :ref="baseSwiperId"
      v-slot="slotProps"
      :items="items"
      :options="swiperOptions"
      class="mt-[16px] overflow-x"
    >
      <CardTile
        class="border-[0px] drop-shadow-tile relative flex flex-col !w-[152px] !h-[189.15px]"
        image-class="rounded-[12px] !w-[152px] !h-[189.15px] overflow-hidden"
        :image-src="slotProps.item.imageSrc"
        :is-downloadable="true"
      />
    </BaseSwiper>
  </div>
</template>

<script>
import BaseSwiper from "@/components/design/BaseSwiper.vue";
import CardTile from "@/components/design/CardTile.vue";

export default {
  components: {
    BaseSwiper,
    CardTile,
  },
  props: {
    baseSwiperId: {
      type: String,
    },
    items: {
      type: Array,
      default: () => [],
    },
    swiperOptions: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    breakpoints() {
      return {
        641: {
          slidesPerView: 2,
          centeredSlides: false,
        },
        1024: {
          slidesPerView: 3,
          centeredSlides: false,
        },
        1366: {
          slidesPerView: 4,
          centeredSlides: false,
        },
      };
    },
  },
};
</script>
