<template>
  <div class="relative mt-[50px]">
    <div v-if="track.isComingSoon">
      <img
        src="../../../public/assets/images/milestones/coming-soon-badge.png"
        class="absolute z-10 top-[-50px] left-[calc(50%-50px)] w-[100px] h-[100px] opacity-75"
      />
      <img
        src="../../../public/assets/images/milestones/coming-soon-bg.png"
        class="w-[43vw] h-[29vh] relative mx-auto opacity-50"
      />
    </div>
    <div v-else @click="openDetailsPage">
        <img
          :src="track.trackBadge"
          class="absolute z-10 top-[-50px] left-[calc(50%-50px)] w-[100px] h-[100px]"
        />
        <ion-card class="w-[43vw] h-[29vh] relative bg-[#F1F1F1]">
          <div class="flex flex-col justify-end gap-2 h-full pb-[16px] px-[16px]">
            <h2 class="mt-[32px] font-bold text-lg text-black">
              {{ track.name }}
            </h2>
            <div class="relative flex flex-row w-full h-[32px]">
              <div
                v-for="(milestone, index) in track.milestones.slice(0, 3)"
                :key="index"
                class="absolute top-0"
                :style="{
                  'z-index': index * 10,
                  left: `${index * 24}px`,
                }"
              >
                <img
                  :src="milestone.state[0].badgeIcon"
                  class="w-[32px] h-[32px]"
                />
              </div>
              <div
                v-if="track.milestones.length > 3"
                class="flex w-full justify-end items-center text-[10px] leading-[12px] pr-1"
              >
                + {{ track.milestones.length - 3 }} more
              </div>
            </div>
            <div class="flex flex-row justify-end">
              <img
                src="../../../public/assets/icons/milestones/chevron-right.svg"
                class="w-[16px] h-[16px]"
              />
            </div>
          </div>
        </ion-card>
    </div>
  </div>
</template>

<script>
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
} from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
  },
  props: {
    track: {
      type: Object,
      required: true,
    },
  },
  methods: {
    updateTrackAndMilestones() {
      // Copy milestones to avoid mutating the original data
      const milestonesCopy = JSON.parse(JSON.stringify(this.track.milestones));
      this.$store.dispatch("milestones/updateFilteredTrackName", this.track.name);
      this.$store.dispatch("milestones/updateMilestones", milestonesCopy);
    },
    openDetailsPage() {
      this.updateTrackAndMilestones();
      this.$store.dispatch("milestones/updateShowDetailsPage", true);
      this.$emit('openDetailsPage');
    },
  },
});
</script>
