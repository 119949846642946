<template>
  <div id="calendarList" class="pb-[16px] px-[24px] pt-[75px]">
    <div class="lg:flex lg:flex-col lg:items-center">
      <div v-for="(month, index) in monthEvents" :key="index">
        <div class="text-[20px] leading-[20px] font-black mt-[20px] mb-[12px]" >{{ month.name }}</div>
        <EventItem 
        v-for="event in month.event" :key="event"
        class="mb-[12px] eventItem"
        :title="event.title"
        :description="event.description"
        :eventTime="event.eventTime"
        :eventLocation="event.eventLocation"
        :startDate="event.startDate"
        :endDate="event.endDate"
        :tags="event.tags"
        :link="event.link"
        :month="month.month"
        :year="month.year"/>
        <Placeholder class="eventItem" v-if="month.event.length == 0" :month="month.month"/>
      </div>
    </div>
  </div>
</template>

<script>
import EventItem from "@/components/Calendar/Events/EventItem.vue";
import Placeholder from "@/components/common/Placeholder.vue";
import eventData from "../../../../public/assets/calendar.json";

export default {
  data() {
    return {
      eventData,
    };
  },
  props: {
    months: {
      type: Array,
      default: []
    },
    monthEvents: {
      type: Array,
      default: []
    }
  },
  methods: {
    findTopVisibleComponent() {
      const components = this.$el.querySelectorAll('.eventItem');
      let topComponent = null;

      Object.values(components).forEach((comp) => {
        const rect = comp.getBoundingClientRect();
        
        if (rect.bottom >= 100 && rect.bottom <= window.innerHeight) {
          if (!topComponent || rect.bottom < topComponent.getBoundingClientRect().bottom) {
            topComponent = comp;
          }
        }
      });
      return topComponent ? topComponent.getAttribute('month') : '';
    }
  },
  components: {
    Placeholder,
    EventItem,
  },
}
</script>
