<template>
  <ion-page>
    <ion-header />
    <ion-content>
      <slot></slot>
    </ion-content>
    <ion-footer>
      <ion-toolbar
        class="bg-white h-[68px] lg:hidden box-shadow"
        :class="{ 'h-[85px]': isIos() }"
      >
        <BottomNav />
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonContent,
  IonFooter,
  IonToolbar,
} from "@ionic/vue";
import BottomNav from "@/components/common/BottomNav";
import { isIosNativeOnly } from "@/utils/platform-check";

export default {
  components: {
    IonPage,
    IonHeader,
    IonContent,
    IonFooter,
    IonToolbar,
    BottomNav,
  },
  methods: {
    isIos() {
      return isIosNativeOnly();
    },
  },
};
</script>

<style scoped>
.footer-md::before {
  display: none;
}
</style>
