<template>
  <!-- To be integrated: bg-[#f6f4f0]  -->
  <div class="flex flex-col h-full">
    <div>
      <!-- original for img: 
         absolute top-[24px] left-6 lg:mt-[80px] cursor-pointer theme-back-button mt-[60px]absolute top-[24px] left-6 lg:mt-[80px] cursor-pointer theme-back-button mt-[60px] -->
      <img
        src="../../../public/assets/icons/back.svg"
        class="px-5 pt-4 lg:mt-[80px] cursor-pointer"
        :class="{ '!pt-[60px]': isIos() }"
        alt="Back icon"
        @click="back"
      />
    </div>
    <div v-if="loading" class="pt-20 mx-auto">Loading...</div>
    <!-- orignal class: dlex flex-col mt-[114px] lg:mt-[144px]" -->
    <div v-else-if="personalId" class="flex flex-col pt-10 px-8">
      <div class="font-black text-[36px] leading-[38px] text-[#242425]">
        My QR Code
      </div>
      <!-- <p class="font-bold mx-auto text-[20px] mb-[12px]">
          Personal ID: {{ personalId }}
        </p> -->
      <!-- 
        Original:
            w-[296px]
            h-[280px]
            bg-[#FFD629]
            rounded-[16px]
            flex flex-col
            pt-[16px]
            px-[48px]
            pb-[32px]
        -->
      <div class="bg-[#FFFFFF] rounded-[20px] mt-20">
        <!-- <p class="mx-auto mb-[8px]">My QR Code</p> -->
        <section
          class="bg-[#ffffff] rounded-[20px] flex p-10 items-center justify-center"
        >
          <qrcode-vue :value="personalId" :size="230" level="H" />
        </section>
      </div>
      <p class="mx-auto text-[16px] leading-[24px] mb-[12px] mt-[16px]">
        Personal ID: {{ personalId }}
      </p>
    </div>
    <div v-else class="pt-[85px] px-[32px]">
      <div
        class="text-[36px] leading-[38px] not-italic font-black text-[#242425]"
      >
        Seems like you don't have a QR code yet
      </div>
      <div
        class="pt-4 text-[16px] leading-[24px] not-italic font-normal text-[#2C2E34]"
      >
        Generate your QR code today
      </div>
      <div class="flex flex-col gap-1 mt-10">
        <div class="text-[#303036] text-[14px] leading-[20px]">Date of Birth</div>
        <input
          type="text"
          class="text-[#787878] w-full px-4 py-2 bg-white border-[#868685] text-[16px] leading-[24px] font-aktiv-grotesk rounded-lg border focus:shadow-outline outline-none"
          placeholder="Enter Date of Birth"
          @input="updateDob"
        />
      </div>
      <div class="flex flex-col gap-1 mt-6">
        <div class="text-[#303036] text-[14px] leading-[20px]">Last 4 char of NRIC / FIN</div>
        <input
          type="text"
          class="text-[#787878] w-full px-4 py-2 bg-white border-[#868685] text-[16px] leading-[24px] font-aktiv-grotesk rounded-lg border focus:shadow-outline outline-none"
          placeholder="Enter Last 4 char of NRIC / FIN"
          @input="updateUin"
        />
      </div>
      <BaseButton
        full-width
        style="line-height: 24px"
        class="flex items-center justify-center mt-6 rounded-[36px] h-[56px]"
        solid-classes="bg-[#3D3A35] text-white text-[16px] leading-[20px] font-semibold"
        @click="updateQRCode"
      >
        Generate QR Code
      </BaseButton>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import BaseButton from "@/components/design/BaseButton.vue";
import QrcodeVue from "qrcode.vue";
import { isIosNativeOnly } from "@/utils/platform-check";

export default {
  components: {
    BaseButton,
    QrcodeVue,
  },

  computed: {
    ...mapState("profile", ["dob", "uin", "personalId", "loading"]),
    ...mapGetters({
      originalPayload: "profile/payload",
    }),
    errorMsg() {
      let details = "";
      if (!this.dob && !this.uin) {
        details = "last 4 characters of your NRIC/FIN and Date of Birth";
      } else if (!this.dob) {
        details = "Date of Birth";
      } else if (!this.uin) {
        details = "last 4 characters of your NRIC/FIN";
      } else if (!this.personalId) {
        return "There a hiccup in generating your Personal ID. Fret not, our friendly IT team will be in touch with you over email / SMS.";
      }
      return `Update your ${details} under ‘Edit Profile’ to view your unique QR code here!`;
    },
    isIos() {
      return isIosNativeOnly;
    },
  },

  methods: {
    back() {
      this.$router.push("/");
    },
    editProfile() {
      this.$router.push("/profile");
    },
    async updateDob (event) {
      await this.$store.commit("profile/setDob", event.target.value);
    },
    async updateUin (event) {
      await this.$store.commit("profile/setUin", event.target.value);
    },
    async updateQRCode(){
      // todo implement update qr code to backend
      // await this.$store.dispatch('profile/updateProfile', this.payload)
      this.$router.push("/my-qr")
    }
  },
};
</script>
