<template>
  <section
    v-if="!$slots.default"
    :class="{ relative: buttonText || cornerText }"
  >
    <img
      class="block w-full h-full"
      :class="imageClass"
      :src="src"
      alt="tile-image"
    />

    <span
      v-if="cornerText"
      class="absolute top-0 right-0 bg-yellow-300 text-[#0b0c0f] text-[16px] leading-[24px] font-bold p-[8px] rounded-tr-[22px] rounded-bl-[10px]"
      :class="cornerTextClass"
    >
      {{ cornerText }}
    </span>

    <div v-if="buttonText" class="absolute bottom-0 left-0 w-full py-4">
      <button
        class="bg-yellow-300 text-[#0b0c0f] text-[16px] leading-[24px] font-bold py-[8px] px-[16px] rounded-[12px] hover:bg-yellow-400"
      >
        {{ buttonText }}
      </button>
    </div>

    <div
      v-if="isDownloadable"
      @click="downloadPhoto()"
      class="absolute bottom-[0px] right-[0px]"
    >
      <img
        src="../../../public/assets/icons/service-highlights/download-yellow.svg"
        alt="Download"
        class="w-[64px] h-[64px]"
      />
    </div>
  </section>

  <section v-else>
    <slot></slot>
  </section>

  <Toast />
</template>

<script>
import { Media } from "@capacitor-community/media";
import Toast from "@/components/common/Toast.vue";

export default {
  components: {
    Toast,
  },
  props: {
    imageClass: {
      type: [String, Array, Object],
      default: null,
    },
    cornerTextClass: {
      type: [String, Array, Object],
      default: null,
    },
    src: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      default: null,
    },
    cornerText: {
      type: String,
      default: null,
    },
    isDownloadable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async downloadPhoto() {
      let opts = { path: this.src };
      try {
        await Media.savePhoto(opts);
        this.$store.dispatch("toast/show", "Successfully downloaded image");
      } catch (e) {
        this.$store.dispatch("toast/error", "Failed to download image");
      }
    },
  },
};
</script>
