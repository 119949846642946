<template>
  <master-layout>
    <div class="flex flex-col w-screen h-[calc(100vh-69px)] bg-[#F6F5F1]">
      <!-- Header and animation Section -->
      <div class="bg-[#F6F5F1]">
        <img
          src="assets/images/homepage/AnimationHeaderPlaceholder.jpg"
          class="w-full rounded-b-[24px] relative z-0"
          alt="animationHeaderBgImage"
        />
      </div>
      <div
        class="flex flex-row justify-end py-2 px-5 h-[48px] z-10 absolute top-5 right-0"
        :class="{ '!top-11': isIos() }"
      >
        <div @click="updates()">
          <img
            src="assets/icons/homepage/notificationIcon.svg"
            class="mr-6 w-[32px] h-[32px] cursor-pointer"
            alt="notification-icon"
          />
          <p
            v-if="unreadCount > 0"
            class="relative top-[-32px] left-[18px] rounded-full bg-[#FF6600] w-min min-w-[16px] h-[16px] text-[12px] text-center leading-[16px] px-[4px] font-bold text-[#FFFFFF]"
          >
            {{ unreadCount }}
          </p>
        </div>
        <div @click="myQr()">
          <img
            src="assets/icons/homepage/qrIcon.svg"
            class="w-[32px] h-[32px] cursor-pointer"
            alt="qrcode-icon"
          />
        </div>
      </div>

      <!-- Middle Nav Icons -->
      <!-- <p>Middle Nav Icons</p> -->
      <!-- {{ middleNavIcons }} -->
      <div
        class="flex flex-row justify-around text-xs text-[#303036] relative z-10 bg-[#F6F5F1] pb-2"
      >
        <div
          v-for="(content, i) in middleNavIcons"
          :key="i"
          class="flex flex-col lg:ml-10 items-center"
          @click="navigate(content.URL, content.name)"
        >
          <img
            :src="content.icon.url"
            alt="HOGC One Homepage Calendar"
            class="relative top-[-32px] object-contain self-center h-[64px] w-[64px] pb-[8px] cursor-pointer"
          />
          <div class="relative top-[-32px]">{{ content.name }}</div>
        </div>
      </div>

      <!-- Featured Carousel -->
      <div
        v-if="featuredCards[0].featuredCards.length > 0"
        class="relative bg-[#F6F5F1] pb-5"
      >
        <div
          class="font-black text-[24px] leading-[25px] px-[20px] text-[#3D3A35] mb-[16px]"
        >
          Featured
        </div>
        <!-- Note: featuredCards[0] is for testing purpose -->
        <FeaturedSlider
          ref="home-page-features"
          baseSwiperId="home-page-features"
          :events="featuredCards[0].featuredCards"
          :options="swiperOptions"
        />
      </div>
      <!-- <div>
        {{ featuredCards }}
      </div> -->

      <!-- <p>Just For You</p>
      <div>
        {{ justForYou }}
      </div> -->

      <!-- Just For You -->
      <div
        v-if="justForYou[0].justForYouCards.length > 0"
        class="lg:mt-10 relative bg-[#F6F5F1] pb-5"
      >
        <h1
          class="font-black text-[24px] leading-[25px] px-[20px] text-[#3D3A35] mb-[16px]"
        >
          Just for you
        </h1>
        <!-- Note: justForYou[0] is for testing purpose -->
        <FeaturedSlider
          ref="home-page-features"
          baseSwiperId="home-page-features"
          :events="justForYou[0].justForYouCards"
          :options="swiperOptions"
        />
      </div>
    </div>
  </master-layout>
</template>
<script>
import { mapState } from "vuex";
import { isIosNativeOnly } from "@/utils/platform-check";
import FeaturedSlider from "@/components/HomePage/FeaturedSlider.vue";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { isPlatform } from "@ionic/vue";

export default {
  components: { FeaturedSlider },
  data() {
    return {
      swiperOptions: {
        centeredSlides: false,
        slidesPerView: "auto",
        grabCursor: true,
        pagination: {
          el: ".swiper-pagination",
          dynamicBullets: true,
          clickable: true,
        },
        hasNavigation: true,
      },
    };
  },
  computed: {
    ...mapState("homepage", ["middleNavIcons", "featuredCards", "justForYou"]),
    ...mapState("updates", ["unreadCount"]),
  },
  async ionViewWillEnter() {
    await this.$store.dispatch("homepage/getMiddleNavIcons"); // trigger get middle nav icons
    await this.$store.dispatch("homepage/getFeaturedCards"); // trigger get featured cards
    await this.$store.dispatch("homepage/getJustForYou"); // trigger get just for you
    await this.$store.dispatch("updates/getUpdates", "all");
  },
  methods: {
    isIos() {
      return isIosNativeOnly();
    },
    async updates() {
      const isNative = isPlatform("android") || isPlatform("ios");
      const isNativeBrowser = isPlatform("mobileweb");
      if (isNative && !isNativeBrowser) {
        FirebaseAnalytics.logEvent({
          name: "click_button",
          params: {
            button_name: "Notification",
            page: "HomePage",
          },
        });
      }
      await this.$store.dispatch("updates/getUpdates", "all");
      await this.$store.commit("updates/setActiveButton", "all");
      this.$router.push("/updates");
    },
    navigate(url, buttonName) {
      const isNative = isPlatform("android") || isPlatform("ios");
      const isNativeBrowser = isPlatform("mobileweb");
      if (isNative && !isNativeBrowser) {
        FirebaseAnalytics.logEvent({
          name: "click_button",
          params: {
            button_name: buttonName,
            page: "HomePage",
          },
        });
      }
      if (url) {
        this.$router.push(url);
      }
    },
    myQr() {
      const isNative = isPlatform("android") || isPlatform("ios");
      const isNativeBrowser = isPlatform("mobileweb");
      if (isNative && !isNativeBrowser) {
        FirebaseAnalytics.logEvent({
          name: "click_button",
          params: {
            button_name: "QR Code - Home",
            page: "HomePage",
          },
        });
      }
      this.$router.push("/my-qr");
    },
  },
};
</script>
